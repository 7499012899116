import { RoleService } from './../../services/role/role.service';
import { TypeRoleGroupData } from './../../models/role';
import { UserService } from './../../services/user/user.service';
import { RuleActionParam } from 'src/app/models/rule-action-param';
import { RuleAction } from './../../models/rule-action';
import { RuleCondition } from './../../models/rule-condition';
import { Rule } from 'src/app/models/rule';
import { TriggerDefParamService } from './../../services/trigger-def-param/trigger-def-param.service';
import { RuleActionParamService } from './../../services/rule-action-param/rule-action-param.service';
import { RuleActionService } from './../../services/rule-action/rule-action.service';
import { RuleConditionService } from './../../services/rule-condition/rule-condition.service';
import { RuleRunsService } from './../../services/rule-runs/rule-runs.service';
import { RuleService } from './../../services/rule/rule.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Variables } from 'src/app/helpers/variables';
import * as CryptoJS from 'crypto-js';
import { Int64Updater, KeyValue, RunTime } from 'src/app/helpers/key-value';
import { DataCache } from 'src/app/helpers/data-cache';
import { ActionParamService } from 'src/app/services/action-param/action-param.service';
import { RuleActionData } from 'src/app/models/rule-action';
import { TypeRoleGroup } from 'src/app/models/role';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss']
})
export class RuleComponent implements OnInit {
  organisation: any[];

  rules = [];
  ruleruns = [];
  ruleconditions = [];
  ruleactions = [];
  ruleactionparams = [];
  runtimes = [];
  triggerparams = [];

  rule: any;
  ruleaction: any;
  rulecondition: any;

  divname = 'notdisableddiv';

  loading = false;

  rpage = 1;
  rrpage = 1;
  size = 100;

  rloadmore = false;
  rrloadmore = false;
  isnorules = false;
  isdelete = false;
  editmode = false;
  isdetail = false;

  isactionparam = false;
  iscondparam = false;

  activeIndex = 0;
  activeIndex2 = 0;
  start = ' ';
  end = ' ';

  noText = 'No';

  triggertypes = [];
  triggertype: any;

  filteroptions = [];
  filteroption: any;

  istriggertype = false;
  istriggername = false;
  isrulename = false;
  isdefault = true;
  isnone = false;

  filtertypename: any;
  filterrulename: any;
  default: any;

  filtertype = ' ';
  filter = ' ';

  fromrange: any;
  tillrange: any;

  showfilter = true;
  isfilter = false;

  divheight = '';
  divheight2 = '';
  innerdiv = '';
  pickerdiv = '';
  runsdiv = '';
  creatrulediv = '';
  innerinnerdiv = '';
  innerinnerdivin = '';
  contentdiv = '';
  menuheight: any;
  master = 'x';
  detail = 'y';

  rulename: any;
  ruledesc: any;
  status = 'disabled';
  trigger: any;
  isexpires = 'no';
  expiry: Date;

  triggers = [];

  rulenameerror = false;
  ruledescerror = false;
  expiryerror = false;
  triggererror = false;

  iscreate = false;
  isedit = false;

  temprule: any;

  isaddcondparam = false;
  iseditcondparam = false;

  isaddaction = false;
  iseditaction = false;

  rconddesc: any;
  rcondparam: any;
  rcondparamvalue: any;
  rcond: any;

  rconddescerror = false;
  rcondparamerror = false;
  rcondparamvalueerror = false;
  rconderror = false;

  conds = [];

  ishide = false;
  isshow = false;

  tempruleaction: any;
  tempruleactionparams: any;

  actiondefs = [];
  action: any;
  actparams = [];

  ractionerror = false;

  ismanage = false;

  trigparams = [];

  adgroups1 = [];
  adgroups2 = [];

  ruleroles = [];
  iseditable = false;

  gps = [];
  gs = [];

  isadm = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private ruleService: RuleService,
    private rulerunService: RuleRunsService,
    private ruleCondService: RuleConditionService,
    private ruleActionService: RuleActionService,
    private ruleActParamService: RuleActionParamService,
    private trigParamService: TriggerDefParamService,
    private actParamService: ActionParamService,
    private usersgroupsService: UserService,
    private roleService: RoleService
  ) {
    this.organisation = [];
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    //   localStorage.setItem('language', lg);
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }

    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    translate.use('English');

    const v = new Variables();
    const mygroups = localStorage.getItem('mg');
    const gs = CryptoJS.AES.decrypt(mygroups, v.pass).toString(CryptoJS.enc.Utf8);
    if (gs.includes('Administrators-Full_Group')) {
      this.ismanage = true;
      this.isadm = true;
    }

    if (gs.includes('Administrators-Basic_Group')) {
      this.ismanage = true;
    }

    this.translate.get('GENERAL.NO').subscribe((resp: string) => {
      this.noText = resp;
    });

    this.translate.get('GENERAL.TRIGGERTYPE').subscribe((resp: string) => {
      this.translate.get('GENERAL.TRIGGERNAME').subscribe((resp2: string) => {
        this.translate.get('GENERAL.RULENAME').subscribe((resp3: string) => {
          this.translate.get('GENERAL.NONE').subscribe((resp4: string) => {
            this.filteroptions.push({label: resp4, value: resp4});
            this.filteroptions.push({label: resp, value: resp});
            this.filteroptions.push({label: resp2, value: resp2});
            this.filteroptions.push({label: resp3, value: resp3});
          });
        });
      });
    });

    this.translate.get('GENERAL.AUTOMATEDTRIGGER').subscribe((resp: string) => {
      this.translate.get('GENERAL.DIRECTINVOKETRIGGER').subscribe((resp2: string) => {
        this.translate.get('GENERAL.SCHEDULEDTRIGGER').subscribe((resp3: string) => {
          this.triggertypes.push({label: resp, value: resp});
          this.triggertypes.push({label: resp2, value: resp2});
          this.triggertypes.push({label: resp3, value: resp3});
        });
      });
    });
   }

  async ngOnInit() {
    this.onResize(null);
    await this.getRules();
    if (window.innerWidth > 900) {
      if (this.rules != null && this.rules.length > 0) {
        if (this.iscreate === false) {
          await this.ruleClicked(this.rules[0]);
        }
      } else {
        this.onResize(null);
      }
    } else {
      this.onResize(null);
    }

    this.getGroups2();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    // this.usersgroupsService.getGroups().subscribe((res) => {
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          // this.groupList.push({label: res[i].GroupName, value: res[i]});
          this.adgroups1.push(res[i]);
        }
      }

      this.loading = false;
    });
  }

  async getGroups2() {
    const data = await DataCache.groupdata$.toPromise();
    this.gps = [];
    this.gs = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
        this.gs.push(val);
      }
    }
  }

  async getTriggers() {
    this.showLoad();
    this.triggers = [];
    const data = await DataCache.triggerdata$.toPromise().catch();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        if (val.typeRoleGroups !== null && val.typeRoleGroups.length > 0) {
          const v = new Variables();
          const gis = localStorage.getItem('gi');
          const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
          const gids = JSON.parse(gs);
          let show = false;
          for (const va of val.typeRoleGroups) {
            if (va.typeRoleGroup.roleName === 'Initiator' && gids.includes(va.typeRoleGroup.groupId)) {
              show = true;
            }
          }

          if (this.isadm === true) {
            show = true;
          }

          if (show === false) {
            continue;
          }
        } else {
          if (this.isadm === true) {
            // add trigger to list
          } else {
            continue;
          }
        }
        this.triggers.push({ label: val.triggerName, value: val});
      }
    }
    this.hideLoad();
  }

  optionSelected() {
    if (this.filteroption === 'Trigger type') {
      this.istriggertype = true;
      this.istriggername = false;
      this.isrulename = false;
      this.isdefault = false;
      this.isnone = false;
    }

    if (this.filteroption === 'Trigger name') {
      this.istriggertype = false;
      this.istriggername = true;
      this.isrulename = false;
      this.isdefault = false;
      this.isnone = false;
    }

    if (this.filteroption === 'Rule name') {
      this.istriggertype = false;
      this.istriggername = false;
      this.isrulename = true;
      this.isdefault = false;
      this.isnone = false;
    }

    if (this.filteroption === 'None') {
      this.istriggertype = false;
      this.istriggername = false;
      this.isrulename = false;
      this.isdefault = false;
      this.isnone = true;
    }

    if (this.filteroption === null) {
      this.istriggertype = false;
      this.istriggername = false;
      this.isrulename = false;
      this.isdefault = true;
      this.isnone = false;
    }
  }

  triggerSelected() {
  }

  async apply() {
    if (this.isdefault === true) {
      this.translate.get('GENERAL.SELECTFILTERTYPE').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    if (this.isnone === true) {
      this.filtertype = ' ';
      this.filter = ' ';
    }

    if (this.istriggertype === true) {
      if (this.istriggertype === true && (this.triggertype === undefined || this.triggertype === null)) {
        this.translate.get('GENERAL.SELECTTRIGGERTYPE').subscribe((resp: string) => {
          this.confirm(resp);
        });
        return;
      } else {
        this.filtertype = 'trigger-type';
        this.filter = this.triggertype;

        this.filterrulename = null;
        this.filtertypename = null;
      }
    }

    if (this.istriggername === true) {
        if (this.istriggername === true && (this.filtertypename === undefined || this.filtertypename === null
          || this.filtertypename.trim() === '')) {
          this.translate.get('GENERAL.ENTERTRIGGERNAME').subscribe((resp: string) => {
            this.confirm(resp);
          });
          return;
        } else {
          this.filtertype = 'trigger-name';
          this.filter = this.filtertypename;

          this.triggertype = null;
          this.filterrulename = null;
        }
    }

    if (this.isrulename === true) {
        if (this.isrulename === true && (this.filterrulename === undefined || this.filterrulename === null
          || this.filterrulename.trim() === '')) {
          this.translate.get('GENERAL.ENTERRULENAME').subscribe((resp: string) => {
            this.confirm(resp);
          });
          return;
        } else {
          this.filtertype = 'rule-name';
          this.filter = this.filterrulename;

          this.triggertype = null;
          this.filtertypename = null;
        }
    }

    this.rpage = 1;
    this.rules = [];
    await this.getRules();
    if (this.rules != null && this.rules.length > 0) {
      await this.ruleClicked(this.rules[0]);
    }
  }

  async getRules() {
    this.loading = true;
    this.showLoad();

    this.isnorules = false;
    if (this.filter === undefined || this.filter === null || this.filter.trim() === '') {
      this.filter = ' ';
    } else {
      this.filter = this.filter;
    }

    const rul = await this.ruleService.getRules(this.rpage, this.size, 0, this.filtertype, this.filter, 0, 0).toPromise()
    .catch(err => this.handleError(err));

    if (rul != null && rul.length > 0) {
      if (rul.length >= this.size) {
        this.rloadmore = true;
      } else {
        this.rloadmore = false;
      }

      const rls = [];

      for (const val of rul) {
        val.color = 'white';
        const options = [];
        if (val.enabled === 'Y') {
          val.stat = 'Enabled';
          val.statcolor = 'green';
          const opt = await this.translate.get('GENERAL.DISABLE').toPromise();
          options.push({label: opt, value: 'disable',
          command: async (event) => { await this.manageRule(event, val); }});
        } else if (val.enabled === 'N') {
          val.stat = 'Disabled';
          val.statcolor = 'red';
          const opt = await this.translate.get('GENERAL.ENABLE').toPromise();
          options.push({label: opt, value: 'enable',
          command: async (event) => { await this.manageRule(event, val); }});
        }

        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        let perm = false;
        if (val.typeRoleGroups !== undefined && val.typeRoleGroups !== null && val.typeRoleGroups.length > 0) {
          for (const va of val.typeRoleGroups) {
            if (va.typeRoleGroup.roleName === 'Administrator' && gids.includes(va.typeRoleGroup.groupId)) {
              perm = true;
            }
          }
        }

        val.options = [];
        val.isoptions = [];

        if (perm === true || this.isadm === true) {
          val.options = options;
          val.isoptions = true;
        }

        if (this.rpage === 1) {
          rls.push(val);
        } else {
          this.rules.push(val);
        }
      }

      if (this.rpage === 1) {
        this.rules = rls;
      }
    } else {
      this.rloadmore = false;
      if (this.rpage === 1) {
        this.rules = [];
        this.isnorules = true;
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async manageRule(event, value) {
    if (event.item.value === 'enable' || event.item.value === 'disable') {
      await this.enableDisable(event.item.value, value);
    }
  }

  async enableDisable(stat, value) {
    const val = new Int64Updater();
    val.Id = value.ruleId;
    val.Values = [];

    const status = new KeyValue();
    status.Key = 'enabled';
    if (stat === 'enable') {
      status.Value = 'Y';
    } else {
      status.Value = 'N';
    }
    val.Values.push(status);

    this.loading = true;
    this.showLoad();
    const res = await this.ruleService.putRule(val).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      let msg = '';
      if (stat === 'enable') {
        msg = await this.translate.get('GENERAL.RULEENABLED').toPromise().catch();
      } else {
        msg = await this.translate.get('GENERAL.RULEDISABLED').toPromise().catch();
      }
      this.showSuccess(title, msg);

      this.size = this.size * this.rpage;
      this.rpage = 1;

      await this.getRules();

      if (this.rule !== undefined && this.rule !== null && this.rule.ruleId === value.ruleId) {
        if (status.Value === 'Y') {
          this.rule.stat = 'Enabled';
         this.rule.statcolor = 'green';
        } else if (status.Value === 'N') {
          this.rule.stat = 'Disabled';
          this.rule.statcolor = 'red';
        }
      }
    } else {
      // failure
    }

    this.hideLoad();
    this.loading = false;
  }

  async showConfirmDeleteRule() {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + this.rule.ruleName + '?',
    '', 'deleterule');
  }

  async deleteRule() {
    this.showLoad();
    const res = await this.ruleService.deleteRule(this.rule.ruleId).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RULEDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.size = this.size * this.rpage;
      this.rpage = 1;

      this.onRejectDeleteRule();

      await this.getRules();

      if (this.rules != null && this.rules.length > 0) {
        await this.ruleClicked(this.rules[0]);
      }
    }

    this.hideLoad();
  }

  onRejectDeleteRule() {
    this.messageService.clear('deleterule');
  }

  async getRuleRuns() {
    this.loading = true;
    this.showLoad();

    const rruns = await this.rulerunService
    .getRuleRuns(this.rrpage, this.size, ' ', this.rule.ruleName, 0, this.start, this.end).toPromise()
    .catch(err => this.handleError(err));

    if (rruns != null && rruns.length > 0) {
      if (rruns.length >= this.size) {
        this.rrloadmore = true;
      } else {
        this.rrloadmore = false;
      }

      const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
      for (const val of rruns) {
        // val.strt = new Date(val.started.toString().replace('T', ' ') + ' UTC').toLocaleString();
        val.strt = new Date(val.started.toString().replace('T', ' ') + ' UTC')
          // .toLocaleString('fr-FR', doptions);
          .toLocaleString('en-US', doptions);
        if (new Date(val.finished).getFullYear() === 1) {
          val.fin = '-';
          val.runResult = '-';
          val.runComment = '-';
        } else {
          // val.fin = new Date(val.finished.toString().replace('T', ' ') + ' UTC').toLocaleString();
          val.fin = new Date(val.finished.toString().replace('T', ' ') + ' UTC')
            // .toLocaleString('fr-FR', doptions);
            .toLocaleString('en-US', doptions);
        }
        if (val.runResult === 'Passed') {
          val.color = 'green';
        } else if (val.runResult === 'Failed') {
          val.color = 'red';
        } else if (val.runResult === 'Skipped') {
          val.color = 'skyblue';
        }
        this.ruleruns.push(val);
      }
    } else {
      this.rrloadmore = false;
      if (this.rrpage === 1) {
        // no rule runs
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  async getRuleConditions() {
    this.loading = true;
    this.showLoad();

    const ruleconds = await this.ruleCondService.getRuleConditions(this.rule.ruleId, 0).toPromise()
    .catch(err => this.handleError(err));

    const rcs = [];
    if (ruleconds != null && ruleconds.length > 0) {
      for (const val of ruleconds) {
        val.color = 'white';
        rcs.push(val);
      }
    }

    this.ruleconditions = rcs;

    this.loading = false;
    this.hideLoad();
  }

  async getRuleActions() {
    this.loading = true;
    this.showLoad();

    const ruleacts = await this.ruleActionService.getRuleActions(this.rule.ruleId, 0).toPromise()
    .catch(err => this.handleError(err));

    const racts = [];
    if (ruleacts != null && ruleacts.length > 0) {
      for (const val of ruleacts) {
        val.color = 'white';
        racts.push(val);
      }
    }

    this.ruleactions = racts;

    this.loading = false;
    this.hideLoad();
  }

  async getRuleActionParams() {
    this.loading = true;
    this.showLoad();

    const ruleactparams = await this.ruleActParamService.getRuleActionParams(this.rule.ruleId, this.ruleaction.actionId).toPromise()
    .catch(err => this.handleError(err));

    const prams = [];
    if (ruleactparams != null && ruleactparams.length > 0) {
      for (const val of ruleactparams) {
        prams.push(val);
      }
    }

    this.ruleactionparams = prams;

    this.loading = false;
    this.hideLoad();
  }

  async getTriggerParams() {
    this.loading = true;
    this.showLoad();

    const params = await this.trigParamService.getTriggerDefParams(this.rule.triggerId).toPromise()
    .catch(err => this.handleError(err));

    if (params != null && params.length > 0) {
      for (const val of params) {
        val.color = 'white';
        this.triggerparams.push(val);
      }
    }

    this.loading = false;
    this.hideLoad();
  }

  triggerparamClicked(triggerparam) {
    const vs = [];
    for (const val of this.triggerparams) {
      const v: any = [];
      v.triggerDefParamDesc = val.triggerDefParamDesc;
      v.triggerDefParamName = val.triggerDefParamName;
      v.triggerDefinitionId = val.triggerDefinitionId;
      if (val.triggerDefParamName === triggerparam.triggerDefParamName) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }

      vs.push(v);
    }

    this.triggerparams = vs;
  }

  async filterRules() {
    // this.filter = this.filterform.value.filter;
    this.rpage = 1;
    this.rules = [];
    await this.getRules();
  }

  async showAddRule() {
    this.master = 'w';
    this.detail = 'z';
    this.ishide = false;
    this.isshow = true;
    this.cancel2();
    this.temprule = this.rule;
    this.rule = null;
    this.iscreate = true;
    this.onResize(null);
    await this.getTriggers();

    this.adgroups1 = [];
    this.adgroups2 = [];
    await this.getGroups();
  }

  cancel() {
    this.rulename = null;
    this.ruledesc = null;
    this.trigger = null;
    this.isexpires = 'no';
    this.status = 'disabled';
    this.expiry = null;

    this.rulenameerror = false;
    this.ruledescerror = false;
    this.triggererror = false;
    this.expiryerror = false;

    this.iscreate = false;
    this.isedit = false;

    this.rule = this.temprule;

    this.master = 'x';
    this.detail = 'y';

    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  cancel2() {
    this.rulename = null;
    this.ruledesc = null;
    this.trigger = null;
    this.isexpires = 'no';
    this.status = 'disabled';
    this.expiry = null;

    this.rulenameerror = false;
    this.ruledescerror = false;
    this.triggererror = false;
    this.expiryerror = false;

    this.iscreate = false;
    this.isedit = false;

    this.rule = this.temprule;

    this.master = 'w';
    this.detail = 'z';

    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  async create() {
    let crt = true;
    this.rulenameerror = false;
    this.ruledescerror = false;
    this.triggererror = false;
    this.expiryerror = false;
    if (this.rulename === undefined || this.rulename === null || this.rulename.trim() === '') {
      crt = false;
      this.rulenameerror = true;
    }
    if (this.ruledesc === undefined || this.ruledesc === null || this.ruledesc.trim() === '') {
      crt = false;
      this.ruledescerror = true;
    }
    if (this.trigger === undefined || this.trigger === null) {
      crt = false;
      this.triggererror = true;
    }
    if (this.isexpires === 'yes') {
      if (this.expiry === undefined || this.expiry === null) {
        crt = false;
        this.expiryerror = true;
      }
    }

    if (crt === true) {
      const rgroups = [];
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'rule';

          rgroups.push(rgroup);
        }
      }

      // tslint:disable-next-line: prefer-const
      let dt: Date;
      const rl = new Rule();
      if (this.status === 'enabled') {
        rl.Enabled = 'Y';
      } else {
        rl.Enabled = 'N';
      }
      if (this.isexpires === 'yes') {
        rl.Expiration = this.expiry;
      } else {
        rl.Expiration = dt;
      }
      rl.RuleDesc = this.ruledesc;
      rl.RuleName = this.rulename;
      rl.RuleType = this.trigger.triggerType;
      rl.TriggerId = this.trigger.triggerId;
      rl.RoleGroups = rgroups;

      this.showLoad();
      const res = await this.ruleService.postRule(rl).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        this.temprule = null;
        this.size = this.rpage * this.size;
        this.rpage = 1;
        await this.getRules();

        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
        const msg = await this.translate.get('GENERAL.RULECREATED').toPromise();
        this.showSuccess(title, msg);

        this.cancel();

        if (this.rules != null && this.rules.length > 0) {
          for (const val of this.rules) {
            if (val.ruleId === res) {
              await this.ruleClicked(val);
            }
          }
        }
      }

      this.hideLoad();
    }
  }

  async save() {
    let crt = true;
    this.rulenameerror = false;
    this.ruledescerror = false;
    this.triggererror = false;
    this.expiryerror = false;
    if (this.rulename === undefined || this.rulename === null || this.rulename.trim() === '') {
      crt = false;
      this.rulenameerror = true;
    }
    if (this.ruledesc === undefined || this.ruledesc === null || this.ruledesc.trim() === '') {
      crt = false;
      this.ruledescerror = true;
    }
    if (this.trigger === undefined || this.trigger === null) {
      crt = false;
      this.triggererror = true;
    }
    if (this.isexpires === 'yes') {
      if (this.expiry === undefined || this.expiry === null) {
        crt = false;
        this.expiryerror = true;
      }
    }

    if (crt === true) {
      const val = new Int64Updater();
      val.Id = this.rule.ruleId;
      val.Values = [];

      const rname = new KeyValue();
      rname.Key = 'rule_name';
      rname.Value = this.rulename;
      val.Values.push(rname);

      const rdesc = new KeyValue();
      rdesc.Key = 'rule_desc';
      rdesc.Value = this.ruledesc;
      val.Values.push(rdesc);

      const rtype = new KeyValue();
      rtype.Key = 'rule_type';
      rtype.Value = this.trigger.triggerType;
      val.Values.push(rtype);

      const trigger = new KeyValue();
      trigger.Key = 'trigger';
      trigger.Value = this.trigger.triggerId.toString();
      val.Values.push(trigger);

      const expiration = new KeyValue();
      expiration.Key = 'expiration';
      if (this.isexpires === 'yes') {
        expiration.Value = this.expiry.toJSON();
      } else {
        expiration.Value = null;
      }
      val.Values.push(expiration);

      const status = new KeyValue();
      status.Key = 'enabled';
      if (this.status === 'enabled') {
        status.Value = 'Y';
      } else {
        status.Value = 'N';
      }
      val.Values.push(status);

      this.loading = true;
      this.showLoad();

      const res = await this.ruleService.putRule(val).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        await this.closeEdit2();

        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RULEUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        // this.size = this.size * this.rpage;
        // this.rpage = 1;

        // await this.getRules();

        // if (this.rule !== undefined && this.rule !== null) {
        //   if (this.rules !== null && this.rules.length > 0) {
        //     for (const vl of this.rules) {
        //       if (vl.ruleId === this.rule.ruleId) {
        //         if (vl.enabled === 'Y') {
        //           this.rule.stat = 'Enabled';
        //           this.rule.statcolor = 'green';
        //         } else {
        //           this.rule.stat = 'Disabled';
        //           this.rule.statcolor = 'red';
        //         }

        //         if (new Date(vl.expiration).getFullYear() === 1) {
        //           this.translate.get('GENERAL.NEVER').subscribe((resp: string) => {
        //             this.rule.exp = resp;
        //           });
        //         } else {
        //           this.rule.exp = new Date(vl.expiration.toString().replace('T', ' ') + ' UTC').toLocaleString();
        //         }

        //         this.rule.enabled = vl.enabled;
        //         this.rule.expiration = vl.expiration;
        //         this.rule.lastRun = vl.lastRun;
        //         this.rule.ruleDesc = vl.ruleDesc;
        //         this.rule.ruleName = vl.ruleName;
        //         this.rule.ruleType = vl.ruleType;
        //         this.rule.stat = vl.stat;
        //         this.rule.statcolor = vl.statcolor;
        //         this.rule.timeinfo = vl.timeinfo;
        //         this.rule.trigger = vl.trigger;
        //         this.rule.triggerId = vl.triggerId;
        //         this.rule.ruleId = vl.ruleId;
        //         this.rule.typeRoleGroups = vl.typeRoleGroups;
        //         this.rule.roleGroups = vl.roleGroups;
        //       }
        //     }
        //   }
        // }

        // this.cancel();
      } else {
        // failure
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  async saveRoles() {
    this.loading = true;
    this.showLoad();

    // prepare role groups
    const rgroups = [];
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const vall of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = vall.groupId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'rule';
        rgroup.ParentId = this.rule.ruleId;

        rgroups.push(rgroup);
      }
    }

    const rdata = new TypeRoleGroupData();
    rdata.ParentId = this.rule.ruleId;
    rdata.RoleParentType = 'rule';
    rdata.TypeRoleGroup = rgroups;

    const res = await this.roleService.putRole(rdata).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RULEUPDATED').toPromise().catch();
      this.showSuccess(title, 'Rule role groups saved');

      await this.closeEdit2();
    }

    this.hideLoad();
    this.loading = false;
  }

  closeEdit() {
    this.cancel();
  }

  async closeEdit2() {
    this.loading = true;
    this.showLoad();

    this.size = this.size * this.rpage;
    this.rpage = 1;

    await this.getRules();

    if (this.rule !== undefined && this.rule !== null) {
      if (this.rules !== null && this.rules.length > 0) {
        for (const vl of this.rules) {
          if (vl.ruleId === this.rule.ruleId) {
            if (vl.enabled === 'Y') {
              this.rule.stat = 'Enabled';
              this.rule.statcolor = 'green';
            } else {
              this.rule.stat = 'Disabled';
              this.rule.statcolor = 'red';
            }

            if (new Date(vl.expiration).getFullYear() === 1) {
              this.translate.get('GENERAL.NEVER').subscribe((resp: string) => {
                this.rule.exp = resp;
              });
            } else {
              this.rule.exp = new Date(vl.expiration.toString().replace('T', ' ') + ' UTC').toLocaleString();
            }

            this.rule.enabled = vl.enabled;
            this.rule.expiration = vl.expiration;
            this.rule.lastRun = vl.lastRun;
            this.rule.ruleDesc = vl.ruleDesc;
            this.rule.ruleName = vl.ruleName;
            this.rule.ruleType = vl.ruleType;
            this.rule.stat = vl.stat;
            this.rule.statcolor = vl.statcolor;
            this.rule.timeinfo = vl.timeinfo;
            this.rule.trigger = vl.trigger;
            this.rule.triggerId = vl.triggerId;
            this.rule.ruleId = vl.ruleId;
            this.rule.typeRoleGroups = vl.typeRoleGroups;
            this.rule.roleGroups = vl.roleGroups;

            if (this.isadm === true) {
              this.iseditable = true;
            }

            this.ruleroles = [];

            const v = new Variables();
            const gis = localStorage.getItem('gi');
            const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
            const gids = JSON.parse(gs);

            for (const val of this.rule.typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
                this.iseditable = true;
              }

              val.typeRoleGroup.g = null;
              if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
                for (const g of this.gps) {
                  if (val.typeRoleGroup.groupId === g.groupId) {
                    val.typeRoleGroup.g = g.groupName;
                    val.typeRoleGroup.color = 'white';
                  }
                }
              }

              this.ruleroles.push(val.typeRoleGroup);
            }
          }
        }
      }
    }

    // this.cancel();

    this.hideLoad();
    this.loading = false;
  }

  async ruleClicked(rule) {
    this.cancelRuleAction();
    this.isshow = true;
    this.ishide = false;

    this.master = 'w';
    this.detail = 'z';

    this.temprule = rule;
    this.cancel();

    this.isdetail = true;
    this.activeIndex = 0;
    this.activeIndex2 = 0;
    this.editmode = false;

    this.start = ' ';
    this.end = ' ';
    this.fromrange = null;
    this.tillrange = null;

    this.loading = true;
    // const myrule = await this.ruleService.getRules(1, 1, rule.ruleId, ' ', ' ', 0, 0).toPromise()
    // .catch(err => this.handleError(err));

    const myrule = [];
    myrule.push(rule);

    if (myrule !== undefined && myrule !== null && myrule.length > 0) {
      this.rule = myrule[0];

      myrule[0].color = 'white';
      if (myrule[0].enabled === 'Y') {
        myrule[0].stat = 'Enabled';
        myrule[0].statcolor = 'green';
      } else if (myrule[0].enabled === 'N') {
        myrule[0].stat = 'Disabled';
        myrule[0].statcolor = 'red';
      }

      if (new Date(myrule[0].expiration).getFullYear() === 1) {
        this.translate.get('GENERAL.NEVER').subscribe((resp: string) => {
          myrule[0].exp = resp;
        });
      } else {
        const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        myrule[0].exp = new Date(myrule[0].expiration.toString().replace('T', ' ') + ' UTC')
          // .toLocaleString('fr-FR', doptions);
          .toLocaleString('en-US', doptions);
        // myrule[0].exp = new Date(myrule[0].expiration.toString().replace('T', ' ') + ' UTC').toLocaleString();
      }
    } else {
      if (new Date(rule.expiration).getFullYear() === 1) {
        this.translate.get('GENERAL.NEVER').subscribe((resp: string) => {
          rule.exp = resp;
        });
      } else {
        rule.exp = new Date(rule.expiration.toString().replace('T', ' ') + ' UTC').toLocaleString();
      }

      this.rule = rule;
    }
    this.loading = false;

    // localStorage.setItem('rule', JSON.stringify(rule));
    const vs = [];
    for (const val of this.rules) {
      const v:any = [];
      v.enabled = val.enabled;
      v.expiration = val.expiration;
      v.lastRun = val.lastRun;
      v.ruleDesc = val.ruleDesc;
      v.ruleName = val.ruleName;
      v.ruleType = val.ruleType;
      v.stat = val.stat;
      v.statcolor = val.statcolor;
      v.timeinfo = val.timeinfo;
      v.trigger = val.trigger;
      v.triggerId = val.triggerId;
      v.ruleId = val.ruleId;
      v.typeRoleGroups = val.typeRoleGroups;
      v.roleGroups = val.roleGroups;
      v.options = val.options;
      v.isoptions = val.options;
      if (val.ruleId === rule.ruleId) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }

      vs.push(v);
    }

    this.rules = vs;

    this.isactionparam = false;
    this.iscondparam = false;

    this.ruleconditions = [];
    this.ruleactions = [];
    this.runtimes = [];
    this.triggerparams = [];

    await this.getRuleConditions();
    await this.getRuleActions();
    await this.getTriggerParams();

    if (this.rule.ruleType != null && this.rule.ruleType === 'Scheduled trigger') {
      if (this.rule.runTimes != null) {
        if (this.rule.runTimes.includes(':')) {
          const split = this.rule.runTimes.split(';');

          for (const val of split) {
            const time = new RunTime();
            time.time = val;
            time.color = 'white';
            this.runtimes.push(time);
          }
        } else {
          const time = new RunTime();
          time.time = rule.runTimes + ' min';
          time.color = 'white';
          this.runtimes.push(time);
        }
      }
    }

    this.isdetail = true;
    this.loading = false;

    this.master = 'w';
    this.detail = 'z';

    this.rulecondition = null;
    this.ruleaction = null;

    if (this.isadm === true) {
      this.iseditable = true;
    }

    if (rule.typeRoleGroups !== null && rule.typeRoleGroups.length > 0) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      this.ruleroles = [];

      for (const val of rule.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.iseditable = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
              val.typeRoleGroup.color = 'white';
            }
          }
        }

        this.ruleroles.push(val.typeRoleGroup);
      }
    }

    this.onResize(null);
  }

  ruleRoleClicked(chanuser: any): any {
    const mcusers = [];
    for (const val of this.ruleroles) {
      const mcu: any = [];
      mcu.parentId = val.parentId;
      mcu.groupId = val.groupId;
      mcu.g = val.g;
      mcu.roleName = val.roleName;
      mcu.roleParentType = val.roleParentType;
      if (chanuser.groupId === val.groupId && chanuser.roleName === val.roleName) {
        mcu.color = 'lightblue';
      } else {
        mcu.color = 'white';
      }
      mcusers.push(mcu);
    }
    this.ruleroles = mcusers;
  }

  async ruleActionClicked(action) {
    this.ruleaction = action;
    this.isaddaction = false;
    this.iseditaction = false;

    const vs = [];
    for (const val of this.ruleactions) {
      const v: any = [];
      v.actionDesc = val.actionDesc;
      v.actionId = val.actionId;
      v.actionName = val.actionName;
      v.enabled = val.enabled;
      v.expiration = val.expiration;
      v.position = val.position;
      v.ruleDesc = val.ruleDesc;
      v.ruleId = val.ruleId;
      v.ruleName = val.ruleName;
      v.ruleType = val.ruleType;
      v.triggerId = val.triggerId;

      if (val.actionId === action.actionId) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }

      vs.push(v);
    }

    this.ruleactions = vs;

    for (const val of this.ruleconditions) {
      val.color = 'white';
    }

    this.ruleactionparams = [];
    await this.getRuleActionParams();

    this.isactionparam = true;
    // this.iscondparam = false;

    if (this.isedit === true) {
      this.showEditRuleAction();
    }

    this.loading = false;
  }

  async ruleConditionClicked(cond) {
    this.rulecondition = cond;

    const vcs = [];
    for (const v of this.ruleconditions) {
      const val: any = [];
      val.conditionDesc = v.conditionDesc;
      val.conditionId = v.conditionId;
      val.conditionName = v.conditionName;
      val.enabled = v.enabled;
      val.expiration = v.expiration;
      val.position = v.position;
      val.ruleConditionDesc = v.ruleConditionDesc;
      val.ruleConditionParamName = v.ruleConditionParamName;
      val.ruleConditionParamValue = v.ruleConditionParamValue;
      val.ruleDesc = v.ruleDesc;
      val.ruleId = v.ruleId;
      val.ruleName = v.ruleName;
      val.ruleType = v.ruleType;
      val.triggerId = v.triggerId;
      if (val.conditionId === cond.conditionId && val.ruleConditionDesc === cond.ruleConditionDesc
        && val.ruleConditionParam === cond.ruleConditionParam && val.ruleConditionParamValue ===
        cond.ruleConditionParamValue && val.position === cond.position) {
        val.color = 'lightblue';
      } else {
        val.color = 'white';
      }

      vcs.push(val);
    }

    this.ruleconditions = vcs;

    for (const val of this.ruleactions) {
      val.color = 'white';
    }

    this.cancelRuleCond();
    // this.isactionparam = false;
    this.iscondparam = true;

    if (this.isedit === true) {
      this.showEditRuleConditionParam();
    }
  }

  async showEditRuleConditionParam() {
    this.showLoad();
    this.cancelRuleCond();
    const data = await DataCache.conddefdata$.toPromise();
    this.conds = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.conds.push({label: val.conditionName, value: val});
      }
    }

    this.rconddesc = this.rulecondition.ruleConditionDesc;
    this.rcondparam = this.rulecondition.ruleConditionParamName;
    if (this.rulecondition.conditionName === 'IsEmpty') {
      this.rcondparamvalue = null;
    } else {
      this.rcondparamvalue = this.rulecondition.ruleConditionParamValue;
    }
    for (const val of this.conds) {
      if (val.value.conditionName === this.rulecondition.conditionName) {
        this.rcond = val.value;
      }
    }

    this.trigparams = [];
    for (const val of this.triggerparams) {
      this.trigparams.push({ label: val.triggerDefParamName, value: val.triggerDefParamName });
    }

    this.iseditcondparam = true;
    this.hideLoad();
  }

  async addRuleCond() {
    let crt = true;
    this.rconddescerror = false;
    this.rcondparamerror = false;
    this.rcondparamvalueerror = false;
    this.rconderror = false;
    if (this.rconddesc === undefined || this.rconddesc === null || this.rconddesc.trim() === '') {
      crt = false;
      this.rconddescerror = true;
    }
    if (this.rcondparam === undefined || this.rcondparam === null || this.rcondparam.trim() === '') {
      crt = false;
      this.rcondparamerror = true;
    }
    if (this.rcond === undefined || this.rcond === null) {
      crt = false;
      this.rconderror = true;
    }
    if ((this.rcond !== undefined && this.rcond !== null && this.rcond.conditionName !== 'IsEmpty')
    || this.rcond === undefined || this.rcond === null) {
      if (this.rcondparamvalue === undefined || this.rcondparamvalue === null || this.rcondparamvalue.trim() === '') {
        crt = false;
        this.rcondparamvalueerror = true;
      }
    }

    if (crt === true) {
      const rc = new RuleCondition();
      rc.ConditionId = this.rcond.conditionId;
      rc.RuleConditionDesc = this.rconddesc;
      rc.RuleConditionParamName = this.rcondparam;

      if (this.rcond.conditionName !== 'IsEmpty') {
        rc.RuleConditionParamValue = this.rcondparamvalue;
      } else {
        rc.RuleConditionParamValue = 'IsEmpty';
      }
      rc.RuleId = this.rule.ruleId;
      if (this.ruleconditions !== undefined && this.ruleconditions !== null && this.ruleconditions.length > 0) {
        rc.Position = this.ruleconditions.length + 1;
      } else {
        rc.Position = 1;
      }

      this.showLoad();

      const res = await this.ruleCondService.postRuleCondition(rc).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RULECONDITIONADDED').toPromise().catch();
        this.showSuccess(title, msg);

        await this.getRuleConditions();

        if (this.ruleconditions !== null && this.ruleconditions.length > 0) {
          this.ruleConditionClicked(this.ruleconditions[0]);
        }
      }

      this.hideLoad();
    }
  }

  async saveRuleCond() {
    let crt = true;
    this.rconddescerror = false;
    this.rcondparamerror = false;
    this.rcondparamvalueerror = false;
    this.rconderror = false;
    if (this.rconddesc === undefined || this.rconddesc === null || this.rconddesc.trim() === '') {
      crt = false;
      this.rconddescerror = true;
    }
    if (this.rcondparam === undefined || this.rcondparam === null || this.rcondparam.trim() === '') {
      crt = false;
      this.rcondparamerror = true;
    }
    if (this.rcond === undefined || this.rcond === null) {
      crt = false;
      this.rconderror = true;
    }
    if (this.rcond !== undefined && this.rcond !== null && this.rcond.conditionName !== 'IsEmpty'
    || this.rcond === undefined || this.rcond === null) {
      if (this.rcondparamvalue === undefined || this.rcondparamvalue === null || this.rcondparamvalue.trim() === '') {
        crt = false;
        this.rcondparamvalueerror = true;
      }
    }

    if (crt === true) {
      const rc = new RuleCondition();
      rc.ConditionId = this.rcond.conditionId;
      rc.RuleConditionDesc = this.rconddesc;
      rc.RuleConditionParamName = this.rcondparam;

      if (this.rcond.conditionName !== 'IsEmpty') {
        rc.RuleConditionParamValue = this.rcondparamvalue;
      } else {
        rc.RuleConditionParamValue = 'IsEmpty';
      }
      rc.RuleId = this.rule.ruleId;
      rc.Position = this.rulecondition.position;

      this.showLoad();

      const res = await this.ruleCondService.updateRuleCondition(this.rule.ruleId, rc).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RULECONDITIONUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        await this.getRuleConditions();

        if (this.ruleconditions !== null && this.ruleconditions.length > 0) {
          for (const val of this.ruleconditions) {
            if (val.conditionId === rc.ConditionId
              && val.ruleConditionDesc === rc.RuleConditionDesc
              && val.ruleConditionParamName === rc.RuleConditionParamName
              && val.ruleConditionParamValue === rc.RuleConditionParamValue
              && val.position === rc.Position) {
                this.ruleConditionClicked(val);
              }
          }
        }
      }

      this.hideLoad();
    }
  }

  async showConfirmDeleteRuleCond(cond) {
    this.rulecondition = cond;
    const title = await this.translate.get('GENERAL.DELETERULECONDITION').toPromise().catch(err => console.log(err));
    this.showConfirm(title + '?',
    '', 'deleterulecond');
  }

  async deleteRuleCond() {
    this.showLoad();
    const cd = new RuleCondition();
    cd.ConditionId = this.rulecondition.conditionId;
    cd.Position = this.rulecondition.position;
    cd.RuleConditionDesc = this.rulecondition.ruleConditionDesc;
    cd.RuleConditionParamName = this.rulecondition.ruleConditionParamName;
    cd.RuleConditionParamValue = this.rulecondition.ruleConditionParamValue;
    cd.RuleId = this.rulecondition.ruleId;
    const res = await this.ruleCondService.deleteRuleCondition(this.rule.ruleId, cd).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RULECONDITIONDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteRuleCond();

      await this.getRuleConditions();

      if (this.ruleconditions != null && this.ruleconditions.length > 0) {
        await this.ruleConditionClicked(this.ruleconditions[0]);
      } else {
        this.iscondparam = false;
      }
    }

    this.hideLoad();
  }

  onRejectDeleteRuleCond() {
    this.messageService.clear('deleterulecond');
  }

  cancelRuleCond() {
    this.iseditcondparam = false;
    this.isaddcondparam = false;
    this.rconddesc = null;
    this.rcondparam = null;
    this.rcondparamvalue = null;
    this.rcond = null;

    this.rconddescerror = false;
    this.rcondparamerror = false;
    this.rcondparamvalueerror = false;
    this.rconderror = false;

    if (this.rulecondition === undefined || this.rulecondition === null) {
      this.iscondparam = false;
    }
  }

  async showAddRuleCond() {
    this.showLoad();
    const data = await DataCache.conddefdata$.toPromise();
    this.conds = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.conds.push({label: val.conditionName, value: val});
      }
    }
    this.cancelRuleCond();
    this.isaddcondparam = true;
    this.isactionparam = false;
    this.iscondparam = true;

    this.trigparams = [];
    for (const val of this.triggerparams) {
      this.trigparams.push({ label: val.triggerDefParamName, value: val.triggerDefParamName });
    }

    this.hideLoad();
  }

  showEditRuleAction() {
    this.tempruleaction = this.ruleaction;
    this.tempruleactionparams = this.ruleactionparams;
    this.actparams = [];
    for (const val of this.ruleactionparams) {
      val.paramMandatory = val.paramMand;
      val.iserror = false;

      this.actparams.push(val);
    }

    this.iseditaction = true;
    this.isaddaction = false;
  }

  async showConfirmDeleteRuleAction(action) {
    this.ruleaction = action;
    const title = await this.translate.get('GENERAL.DELETERULEACTION').toPromise().catch(err => console.log(err));
    this.showConfirm(title + '?',
    '', 'deleteruleaction');
  }

  onRejectDeleteRuleAction() {
    this.messageService.clear('deleteruleaction');
  }

  async deleteRuleAction() {
    this.showLoad();
    const res = await this.ruleActionService.deleteRuleAction(this.rule.ruleId, this.ruleaction.actionId).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.RULEACTIONDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteRuleAction();

      await this.getRuleActions();

      this.cancelRuleAction();
      if (this.ruleactions != null && this.ruleactions.length > 0) {
        await this.ruleActionClicked(this.ruleactions[0]);
      } else {
        this.isactionparam = false;
      }
    }

    this.hideLoad();
  }

  async showAddRuleAction() {
    this.showLoad();
    this.tempruleaction = this.ruleaction;
    this.tempruleactionparams = this.ruleactionparams;
    this.isaddaction = true;
    this.iseditaction = false;
    this.actparams = [];
    this.actiondefs = [];
    const data = await DataCache.actiondefdata$.toPromise();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        let isadd = true;
        if (this.ruleactions === undefined || this.ruleactions === null || this.ruleactions.length === 0) {
          // this.actiondefs.push({label: val.actionName, value: val});
          isadd = true;
        } else {
          for (const va of this.ruleactions) {
            if (va.actionId === val.actionId) {
              isadd = false;
            }
          }
        }
        if (isadd === true) {
          this.actiondefs.push({label: val.actionName, value: val});
        }
      }
    }

    this.ractionerror = false;

    this.isaddaction = true;
    this.iseditaction = false;
    this.hideLoad();
  }

  async actionDefSelected() {
    this.showLoad();
    this.ractionerror = false;

    const data = await this.actParamService.getActionParams(this.action.actionId, ' ').toPromise()
    .catch(err => this.handleError(err));
    this.actparams = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        val.iserror = false;
        val.paramValue = null;
        this.actparams.push(val);
      }
    }
    this.hideLoad();
  }

  cancelRuleAction() {
    this.actiondefs = [];
    this.action = null;
    this.actparams = [];
    this.isaddaction = false;
    this.iseditaction = false;
    this.ruleaction = null;
    this.ruleactionparams = [];
    // this.isactionparam = false;
    this.ruleaction = this.tempruleaction;
    this.ruleactionparams = this.tempruleactionparams;
  }

  async addRuleAction() {
    this.showLoad();
    let iscrt = true;

    this.ractionerror = false;

    if (this.action === undefined || this.action === null) {
      iscrt = false;
      this.ractionerror = true;
    }
    if (this.actparams !== undefined && this.actparams !== null && this.actparams.length > 0) {
      for (const val of this.actparams) {
        if (val.paramMandatory === 'Y' &&
        (val.paramValue === undefined || val.paramValue === null || val.paramValue.trim() === '')) {
          iscrt = false;
          val.iserror = true;
        } else {
          val.iserror = false;
        }
      }
    }

    if (iscrt === true) {
      const rlact = new RuleActionData();

      const ract = new RuleAction();
      ract.ActionId = this.action.actionId;
      ract.RuleId = this.rule.ruleId;
      if (this.ruleactions !== undefined && this.ruleactions !== null && this.ruleactions.length > 0) {
        ract.Position = this.ruleactions.length + 1;
      } else {
        ract.Position = 1;
      }
      rlact.RuleAction = ract;

      rlact.RuleActionParams = [];
      for (const val of this.actparams) {
        const pram = new RuleActionParam();
        pram.ActionId = this.action.actionId;
        pram.ParamName = val.paramName;
        pram.ParamValue = val.paramValue;
        pram.RuleId = this.rule.ruleId;

        rlact.RuleActionParams.push(pram);
      }

      const res = await this.ruleActionService.postRuleAction(rlact).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RULEACTIONCREATED').toPromise().catch();
        this.showSuccess(title, msg);

        await this.getRuleActions();

        const aid = this.action.actionId;
        this.cancelRuleAction();
        if (this.ruleactions !== undefined && this.ruleactions !== null && this.ruleactions.length > 0) {
          for (const val of this.ruleactions) {
            if (aid === val.actionId) {
              this.ruleActionClicked(val);
            }
          }
        }
      }
    }

    this.hideLoad();
  }

  async saveRuleAction() {
    this.showLoad();
    let iscrt = true;

    this.ractionerror = false;

    if (this.actparams !== undefined && this.actparams !== null && this.actparams.length > 0) {
      for (const val of this.actparams) {
        if (val.paramMandatory === 'Y' &&
        (val.paramValue === undefined || val.paramValue === null || val.paramValue.trim() === '')) {
          iscrt = false;
          val.iserror = true;
        } else {
          val.iserror = false;
        }
      }
    }

    if (iscrt === true) {
      const ruleActionParams = [];
      for (const val of this.actparams) {
        const pram = new RuleActionParam();
        pram.ActionId = this.ruleaction.actionId;
        pram.ParamName = val.paramName;
        pram.ParamValue = val.paramValue;
        pram.RuleId = this.rule.ruleId;

        ruleActionParams.push(pram);
      }

      const res = await this.ruleActionService.updateRuleAction(this.rule.ruleId, ruleActionParams).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
        const msg = await this.translate.get('GENERAL.RULEACTIONUPDATED').toPromise().catch();
        this.showSuccess(title, msg);

        await this.getRuleActions();

        // this.cancelRuleAction();

        if (this.ruleactions !== undefined && this.ruleactions !== null && this.ruleactions.length > 0) {
          for (const val of this.ruleactions) {
            if (this.ruleaction.actionId === val.actionId) {
              this.ruleActionClicked(val);
            }
          }
        }
      }
    }

    this.hideLoad();
  }

  async hideData() {
    this.ishide = true;
    this.isshow = false;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  showData() {
    this.ishide = false;
    this.isshow = true;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  async runTimeClicked(time) {

  }

  startEditMode() {
    this.editmode = true;
    this.divname = 'disableddiv';
  }

  exitEditMode() {
    this.editmode = false;
    this.divname = 'notdisableddiv';
  }

  async handleChange(e) {
    if (e.index === 1) {
      this.rrpage = 1;
      this.ruleruns = [];
      await this.getRuleRuns();
    }
  }

  async showEditRule() {
    this.temprule = this.rule;
    this.rulename = null;
    this.ruledesc = null;
    this.trigger = null;
    this.isexpires = 'no';
    this.status = 'disabled';
    this.expiry = null;

    this.rulenameerror = false;
    this.ruledescerror = false;
    this.triggererror = false;
    this.expiryerror = false;

    this.iscreate = false;
    this.isedit = false;

    await this.getTriggers();

    this.rulename = this.rule.ruleName;
    this.ruledesc = this.rule.ruleDesc;
    if (this.rule.enabled === 'Y') {
      this.status = 'enabled';
    } else {
      this.status = 'disabled';
    }
    if (this.rule.expiration === null || new Date(this.rule.expiration).getFullYear() === 1) {
      this.isexpires = 'no';
      this.expiry = null;
    } else {
      this.isexpires = 'yes';
      // this.expiry = new Date(new Date(this.rule.expiration).toLocaleDateString());
      this.expiry = new Date(this.rule.expiration.toString().replace('T', ' ') + ' UTC');
    }

    for (const val of this.triggers) {
      if (val.value.triggerId === this.rule.triggerId) {
        this.trigger = val.value;
      }
    }

    if (this.rulecondition !== undefined && this.rulecondition !== null) {
      this.showEditRuleConditionParam();
    }

    if (this.ruleaction !== undefined && this.ruleaction !== null) {
      this.showEditRuleAction();
    }

    await this.getGroups2();
    this.arrangeRoles();

    this.isedit = true;
    this.onResize(null);
    this.isedit = true;
  }

  arrangeRoles() {
    this.adgroups1 = [];
    this.adgroups2 = [];

    if (this.gs !== null && this.gs.length > 0) {
      if (this.rule.typeRoleGroups !== null && this.rule.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ad = true;
          for (const val of this.rule.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
        }
      }
    }
  }

  async loadMoreR() {
    this.rpage += 1;
    await this.getRules();
  }

  async loadMoreRR() {
    this.rrpage += 1;
    await this.getRuleRuns();
  }

  async applyFilterRuns() {
    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.fromrange > this.tillrange) {
        this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.start = this.fromrange.toJSON().toString();
      this.end = this.tillrange.toJSON().toString();

      this.loading = true;

      // call function here
      this.rrpage = 1;
      this.ruleruns = [];
      await this.getRuleRuns();

      this.loading = false;
    }
  }

  toggleFilter(): any {
    this.isfilter = !this.isfilter;
  }

  set() {
    this.fromrange = new Date(2020, 1, 9, 3, 3, 3, 3);
    this.tillrange = new Date();
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event !== undefined && event !== null) {
      const key = event.key;
      if (key !== undefined && key !== null && key === 'Enter' && this.isfilter === true) {
        this.apply();
      }
    }
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  onResize(event) {
    const menuheight = document.getElementById('menu')?.offsetHeight;
    const contentheight = window.innerHeight;

    const cwidth = document.getElementById('mycontentdiv')?.offsetWidth;
    let cheight = document.getElementById('maindata')?.offsetHeight;
    if (cheight === undefined || cheight === null) {
      cheight = 0;
    }

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (cwidth < 600) {
      // contentheight = contentheight - 20;
      // contentheight = contentheight - 15;
    } else {
    }
    if (this.iscreate === true || this.isedit === true) {
      this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 38) + 'px';
      const pdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 40 - 223);
      const def = 150;
      if (pdiv < def) {
        this.pickerdiv = def + 'px';
        // this.pickerdiv = pdiv + 'px';
      } else {
        this.pickerdiv = pdiv + 'px';
      }
      // this.pickerdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 40 - 265) + 'px';
    } else {
      this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 15) + 'px';
    }
    this.runsdiv = (contentheight - menuheight - 150 + 15) + 'px';
    this.innerinnerdiv = (contentheight - menuheight - cheight - 10 - 200 - 26 + 15) + 'px';
    this.innerinnerdivin = (contentheight - menuheight - cheight - 10 - 200 - 65 + 15) + 'px';
    this.creatrulediv = (contentheight - menuheight - 200 + 12) + 'px';
    // console.log(contentheight, cwidth, cheight, menuheight, this.contentdiv, this.innerdiv, this.runsdiv,
    //  this.innerinnerdivin, this.innerinnerdiv, this.creatrulediv)

    // if (event !== undefined && event !== null && event >= 0) {
    //   setTimeout(() => {
    //     this.onResize(null);
    //   }, 100);
    // }

    if (this.isedit === true && window.innerHeight < 700) {
      this.innerinnerdiv = '100%';
      this.innerinnerdivin = '350px';
    }
  }

  onResizeSmall(event) {
    const menuheight = document.getElementById('menu').offsetHeight;
    let contentheight = window.innerHeight;

    const cwidth = document.getElementById('mycontentdiv').offsetWidth;
    const cheight = document.getElementById('maindata').offsetHeight;

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (cwidth < 600) {
      contentheight = contentheight - 20;
      // contentheight = contentheight - 15;
    } else {
    }
    this.innerdiv = (contentheight - menuheight - cheight - 10 - 200) + 'px';
    this.runsdiv = (contentheight - menuheight - 150) + 'px';
    this.innerinnerdiv = (contentheight - menuheight - cheight - 10 - 200 - 30) + 'px';
    this.innerinnerdivin = (contentheight - menuheight - cheight - 10 - 200 - 70) + 'px';
    // this.innerdiv = (contentheight - menuheight - 107) + 'px';

    let hid = document.getElementById('hiddencard');
    while (hid === null) {
      hid = document.getElementById('hiddencard')
    }
  }

  onResize2(event: any): any {
    // const val = event.target.innerWidth;
    let height = 0;
    if (event === null) {
      height = window.innerHeight;
    } else {
      height = event.target.innerHeight;
    }
    this.contentdiv = (height - this.menuheight - 25) + 'px';
    const headerheight = document.getElementById('myheader')?.offsetHeight;
    const mainheight = document.getElementById('maindata')?.offsetHeight;

    if (headerheight !== undefined && headerheight !== null
      && mainheight !== undefined && mainheight !== null) {
      this.divheight = (((height - this.menuheight - 25) - headerheight - mainheight) - 90) + 'px';
      this.divheight2 = this.divheight;
    }
  }

  setExpry() {
    this.expiry = new Date();
  }

  async handleError(err) {
    this.loading = false;
    this.hideLoad();
    if (err !== undefined && err !== null && err.error !== undefined && err.error !== null) {
      if (err.error.MessageText === 'duplicate key value violates unique constraint "t_rlm_rule_rule_name_key"') {
        const title = await this.translate.get('GENERAL.INFO').toPromise();
        const msg = await this.translate.get('GENERAL.RULEALREADYEXISTS').toPromise();
        this.showWarn(title, msg);
      } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_rlm_rl_act_param_rule_id_act_def_id_param_name_param_val_key"') {
        const title = await this.translate.get('GENERAL.INFO').toPromise();
        const msg = await this.translate.get('GENERAL.RULEACTIONALREADYEXISTS').toPromise();
        this.showWarn(title, msg);
      } else {
        console.log(err);
      }
    } else {
      console.log(err);
    }
  }

}

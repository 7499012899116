import { Rule } from './../../models/rule';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/helpers/urls';
import { Variables } from 'src/app/helpers/variables';
import * as CryptoJS from 'crypto-js';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/helpers/key-value';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRules(page, size, rid, filtertype, filt, trigid, next) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/rule/' + page + '/' + size + '/' + rid + '/' + filtertype + '/' + filt + '/' + trigid + '/' + next + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRule(rule: Rule) {
    return this.httpclient.post(this.apiUrl + '/api/rule', rule)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putRule(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/rule/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRule(id: number) {
    return this.httpclient.delete(this.apiUrl + '/api/rule/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

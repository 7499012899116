import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/helpers/key-value';
import { Urls } from 'src/app/helpers/urls';
import { Rule } from 'src/app/models/rule';

@Injectable({
  providedIn: 'root'
})
export class RuleRunsService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRuleRuns(page, size, runid, rulename, trigid, start, end) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/rulerun/' + page + '/' + size + '/' + runid + '/' + rulename + '/' + trigid + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

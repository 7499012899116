export class KeyValue {
    public Key: string;
    public Value: string;
}

export class StringUpdater {
    public Id: string;
    public Values: KeyValue[];
    public AdditionalValues: string;
}

export class Int64Updater {
    public Id: number;
    public Values: KeyValue[];
    public AdditionalValues: string;
}

export class RunTime {
    public time: string;
    public color: string;
}
import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class Rule {
    public TriggerId: number;
    public RuleId: number;
    public RuleName: string;
    public RuleDesc: string;
    public RuleType: string;
    public Expiration: Date;
    public Enabled: string;
    public LastRun: Date;
    public TimeInfo: Date;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
}

import { RuleActionParam } from './../../models/rule-action-param';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';
import { RuleCondition } from 'src/app/models/rule-condition';

@Injectable({
  providedIn: 'root'
})
export class RuleActionParamService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRuleActionParams(ruleid, actid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/ruleactionparam/' + ruleid + '/' + actid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRuleActionParam(actparam: RuleActionParam) {
    return this.httpclient.post(this.apiUrl + '/api/ruleactionparam', actparam)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putRuleActionParam(actparam: RuleActionParam) {
    return this.httpclient.put(this.apiUrl + '/api/rulecondition/0', actparam)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRuleActionParam(actparam: RuleActionParam) {
    return this.httpclient.put(this.apiUrl + '/api/rulecondition/delete/0', actparam)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/helpers/variables';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = new Urls();
  usmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = this.url.usmApiUrl;
  }

  getUserInfo(uid, empid, email, phone) {
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/users/ ' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroups() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    const orgId = +oi;
    // this.orgId = +localStorage.getItem('organisation-id');
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { TypeRoleGroup, TypeRoleGroupInfo } from './role';
import { TriggerDefParam } from './trigger-def-param';

export class TriggerDefinition {
    public TriggerId: number;
    public TriggerName: string;
    public TriggerDesc: string;
    public TriggerType: string;
    public RunTimes: string
    public LastRun: Date;
    public NextRun: Date;
    public DayOfWeek: number;
    public RunTimes2: string[];
    public DayOfWeek2: number[];
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
    public TriggerParams: TriggerDefParam[];
}

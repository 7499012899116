import { TriggerDefParam } from './../../models/trigger-def-param';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';
import { RuleActionParam } from 'src/app/models/rule-action-param';

@Injectable({
  providedIn: 'root'
})
export class TriggerDefParamService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getTriggerDefParams(trigid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/triggerdefparam/' + trigid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postTriggerDefParam(param: TriggerDefParam) {
    return this.httpclient.post(this.apiUrl + '/api/triggerdefparam', param)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteTriggerDefParam(trigid: number, p: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.apiUrl + '/api/triggerdefparam/delete/' + trigid, p, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

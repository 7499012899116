import { DataCache } from './../../helpers/data-cache';
import { TriggerDefParam } from './../../models/trigger-def-param';
import { TriggerDefinition } from './../../models/trigger-definition';
import { TriggerService } from './../../services/trigger/trigger.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RuleRunsService } from 'src/app/services/rule-runs/rule-runs.service';
import { TriggerDefParamService } from 'src/app/services/trigger-def-param/trigger-def-param.service';
import * as CryptoJS from 'crypto-js';
import { RunTime, Int64Updater, KeyValue } from 'src/app/helpers/key-value';
import { to12Hours } from 'convert-string-time'
import { HttpClient } from '@angular/common/http';
import { Variables } from 'src/app/helpers/variables';
import { RunTimesService } from 'src/app/services/run-times/run-times.service';
import { TriggerTime } from 'src/app/models/trigger-time';
import { UserService } from 'src/app/services/user/user.service';
import { RoleService } from 'src/app/services/role/role.service';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/models/role';

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss']
})
export class TriggerComponent implements OnInit {

  organisation: any[];

  triggerruns = [];
  triggers = [];
  trigger: any;

  runtimes = [];
  triggerparams = [];

  divname = 'notdisableddiv';

  loading = false;

  tpage = 1;
  trpage = 1;
  size = 100;

  tloadmore = false;
  trloadmore = false;
  isnotriggers = false;
  isdelete = false;
  editmode = false;
  isdetail = false;

  activeIndex = 0;
  activeIndex2 = 0;
  start = ' ';
  end = ' ';

  noText = 'No';

  triggertypes = [];
  triggertype: any;

  fromrange: any;
  tillrange: any;

  ishide = false;
  isshow = false;

  iscreate = false;
  isedit = false;

  showfilter = true;
  isfilter = false;

  divheight = '';
  divheight2 = '';
  innerdiv = '';
  pickerdiv = '';
  runsdiv = '';
  runsdiv2 = '';
  creattriggerdiv = '';
  innerinnerdiv = '';
  innerinnerdivin = '';
  contentdiv = '';
  menuheight: any;
  master = 'x';
  detail = 'y';

  ctriggertypes = [];
  ctriggertype: any;

  triggername: any;
  triggerdesc: any;
  durations: any;
  interval: any;

  dow: any;
  dows = [];

  runtimemode = 'Interval';
  disableDurations = true;
  disableInterval = false;

  triggernameerror = false;
  triggerdescerror = false;
  ctriggertypeerror = false;
  intervalerror = false;
  durationserror = false;
  invaliddurations = false;
  dowerror = false;

  temptrigger: any;

  isaddtrigparam = false;

  paramname: any;
  paramdesc: any;

  paramnameerror = false;
  paramdescerror = false;

  trigparam: any;

  isdeletetime = true;
  isaddtime = true;

  showaddtime = false;
  time: Date;
  timeerror = false;
  timeexisterror = false;

  temptime: any;

  iseditinterval = false;
  showeditinterval = false;
  int: number;

  ismanage = false;

  inigroups1 = [];
  inigroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  ruleroles = [];
  iseditable = false;

  gps = [];
  gs = [];

  isadm = false;

  temptriggerparams = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private rulerunService: RuleRunsService,
    private trigParamService: TriggerDefParamService,
    private triggerService: TriggerService,
    private http: HttpClient,
    private runtimeService: RunTimesService,
    private usersgroupsService: UserService,
    private roleService: RoleService
  ) {
    this.organisation = [];
    translate.use('English');

    const v = new Variables();
    const mygroups = localStorage.getItem('mg');
    const gs = CryptoJS.AES.decrypt(mygroups, v.pass).toString(CryptoJS.enc.Utf8);
    if (gs.includes('Administrators-Full_Group')) {
      this.ismanage = true;
      this.isadm = true;
    }

    if (gs.includes('Administrators-Basic_Group')) {
      this.ismanage = true;
    }

    this.translate.get('GENERAL.NO').subscribe((resp: string) => {
      this.noText = resp;
    });

    this.translate.get('GENERAL.AUTOMATEDTRIGGER').subscribe((resp: string) => {
      this.translate.get('GENERAL.DIRECTINVOKETRIGGER').subscribe((resp2: string) => {
        this.translate.get('GENERAL.SCHEDULEDTRIGGER').subscribe((resp3: string) => {
          this.translate.get('GENERAL.ALL').subscribe((resp4: string) => {
            this.triggertypes.push({label: resp4, value: resp4});
            this.triggertypes.push({label: resp, value: resp});
            this.triggertypes.push({label: resp2, value: resp2});
            this.triggertypes.push({label: resp3, value: resp3});
          });
        });
      });
    });
   }

  async ngOnInit() {
    this.onResize(null);
    const val1 = await this.translate.get('GENERAL.AUTOMATEDTRIGGER').toPromise();
    const val2 = await this.translate.get('GENERAL.DIRECTINVOKETRIGGER').toPromise();
    const val3 = await this.translate.get('GENERAL.SCHEDULEDTRIGGER').toPromise();
    this.ctriggertypes.push({label: val1, value: 'Automated trigger'});
    this.ctriggertypes.push({label: val2, value: 'Direct invoke trigger'});
    this.ctriggertypes.push({label: val3, value: 'Scheduled trigger'});

    // day of week
    const all = await this.translate.get('GENERAL.ALL').toPromise();
    const mon = await this.translate.get('GENERAL.MONDAY').toPromise();
    const tue = await this.translate.get('GENERAL.TUESDAY').toPromise();
    const wed = await this.translate.get('GENERAL.WEDNESDAY').toPromise();
    const thur = await this.translate.get('GENERAL.THURSDAY').toPromise();
    const fri = await this.translate.get('GENERAL.FRIDAY').toPromise();
    const sat = await this.translate.get('GENERAL.SATURDAY').toPromise();
    const sun = await this.translate.get('GENERAL.SUNDAY').toPromise();

    // this.dows.push({label: all, value: 0});
    this.dows.push({label: mon, value: 1});
    this.dows.push({label: tue, value: 2});
    this.dows.push({label: wed, value: 3});
    this.dows.push({label: thur, value: 4});
    this.dows.push({label: fri, value: 5});
    this.dows.push({label: sat, value: 6});
    this.dows.push({label: sun, value: 7});

    await this.getTriggers();
    if (this.triggers != null && this.triggers.length > 0 && window.innerWidth > 900) {
      if (this.iscreate === false) {
        await this.triggerClicked(this.triggers[0]);
      }
    } else {
      this.onResize(null);
    }

    this.getGroups2();
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    // this.usersgroupsService.getGroups().subscribe((res) => {
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (const val of res) {
          val.GroupName = val.groupName;
          this.adgroups1.push(val);
          this.inigroups1.push(val);
        }
      }

      this.loading = false;
    });
  }

  async getGroups2() {
    const data = await DataCache.groupdata$.toPromise();
    this.gps = [];
    this.gs = [];
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
        this.gs.push(val);
      }
    }
  }

  async getTriggers() {
    this.loading = true;
    this.isnotriggers = false;
    this.showLoad();

    let trigtype = ' ';

    if (this.triggertype !== undefined && this.triggertype !== null) {
      if (this.triggertype === 'All') {
        trigtype = ' ';
      } else {
        trigtype = this.triggertype;
      }
    } else {
      trigtype = ' ';
    }

    const trigs = await this.triggerService.getTriggers(0, ' ', trigtype, 0).toPromise()
    .catch(err => this.handleError(err));

    if (this.tpage === 1) {
      this.triggers = [];
    }

    if (trigs !== undefined && trigs !== null && trigs.length > 0) {
      if (trigs.length >= this.size) {
        this.tloadmore = true;
      } else {
        this.tloadmore = false;
      }

      for (const val of trigs) {
        val.color = 'white';

        if (val.triggerType === 'Scheduled trigger') {
          if (val.runTimes !== undefined && val.runTimes !== null) {
            const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            const dow = new Date(val.nextRun).getDay();
            const now = new Date().getDay();
            if (now !== dow) {
              // same day
              // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC').toLocaleString();
              const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
                hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
              // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC')
              // .toLocaleString('fr-FR', doptions);
              val.next = new Date(val.nextRun)
              .toLocaleString('en-US', doptions);
              // val.next = new Date(val.nextRun).toLocaleString('fr-FR', doptions);
              // .toLocaleString('en-US', doptions);
            } else {
              // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC').toLocaleString();
              const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
              hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
              // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC')
              // .toLocaleString('fr-FR', doptions);
              val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC')
              .toLocaleString('en-US', doptions);
              // val.next = new Date(val.nextRun).toLocaleString('fr-FR', doptions);
              // .toLocaleString('en-US', doptions);
            }
          } else {
            // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC').toLocaleString();
            const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
              hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
            // val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC')
            // .toLocaleString('fr-FR', doptions);
            val.next = new Date(val.nextRun.toString().replace('T', ' ') + ' UTC')
            .toLocaleString('en-US', doptions);
            // val.next = new Date(val.nextRun).toLocaleString('fr-FR', doptions);
            // .toLocaleString('en-US', doptions);
          }
        } else {
          val.next = '';
        }

        this.triggers.push(val);
      }
    } else {
      this.tloadmore = false;
      if (this.tpage === 1) {
        this.isnotriggers = true;
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  async apply() {
    if (this.triggertype === undefined || this.triggertype === null) {
      this.translate.get('GENERAL.SELECTTRIGGERTYPE').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    this.tpage = 1;
    this.triggers = [];
    await this.getTriggers();
    if (this.triggers != null && this.triggers.length > 0) {
      await this.triggerClicked(this.triggers[0]);
    }
  }

  async showAddTrigger() {
    this.cancel2();
    this.iscreate = true;
    this.temptrigger = this.trigger;
    this.dow = 0;

    this.master = 'w';
    this.detail = 'z';

    this.isshow = true;
    this.ishide = false;

    this.onResize(null);

    this.temptriggerparams = [];

    this.adgroups1 = [];
    this.adgroups2 = [];

    this.inigroups1 = [];
    this.inigroups2 = [];

    this.getGroups();
  }

  async showEditTrigger() {
    this.cancel2();
    this.temptrigger = this.trigger;

    this.ctriggertype = this.trigger.triggerType;
    this.triggername = this.trigger.triggerName;
    this.triggerdesc = this.trigger.triggerDesc;
    if (this.trigger.triggerType === 'Scheduled trigger') {
      // if (this.trigger.runTimes.includes(':')) {
      if (this.trigger.runTimes === undefined || this.trigger.runTimes === null) {
        this.runtimemode = 'Durations';
        this.durations = this.trigger.runTimes2;
        // this.durations = this.trigger.runTimes;
        this.disableDurations = false;
        this.disableInterval = true;
      } else {
        this.runtimemode = 'Interval';
        this.interval = +this.trigger.runTimes;
        this.disableDurations = true;
        this.disableInterval = false;
      }

      this.dow = this.trigger.dayOfWeek2;
      // this.dow = +this.trigger.dayOfWeek;
    }

    this.temptriggerparams = [];
    if (this.triggerparams !== undefined && this.triggerparams !== null) {
      for (const val of this.triggerparams) {
        const tparam = new TriggerDefParam();
        tparam.TriggerDefParamDesc = val.triggerDefParamDesc;
        tparam.TriggerDefParamName = val.triggerDefParamName;
        tparam.TriggerDefinitionId = val.triggerDefinitionId;

        this.temptriggerparams.push(tparam);
      }
    }

    this.adgroups1 = [];
    this.adgroups2 = [];

    this.inigroups1 = [];
    this.inigroups2 = [];

    await this.getGroups2();
    this.arrangeRoles();

    this.isedit = true;

    this.onResize(null);
  }

  arrangeRoles() {
    this.adgroups1 = [];
    this.adgroups2 = [];

    if (this.gs !== null && this.gs.length > 0) {
      if (this.trigger.typeRoleGroups !== null && this.trigger.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ad = true;
          let ini = true;
          for (const val of this.trigger.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }

            if (val.typeRoleGroup.roleName === 'Initiator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.inigroups2.push(v);
                ini = false;
              }
            }
          }

          if (ad === true) {
            this.adgroups1.push(v);
          }

          if (ini === true) {
            this.inigroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
          this.inigroups1.push(val);
        }
      }
    }
  }

  showAddRunTime() {
    this.cancelTime();
    this.showaddtime = true;
  }

  async addTime() {
    let crt = true;
    this.timeerror = false;
    this.timeexisterror = false;
    if (this.time === undefined || this.time === null) {
      crt = false;
      this.timeerror = true;
    } else {
      const thr = (this.time.getHours() < 10 ? '0' : '') + this.time.getHours();
      const tmn = (this.time.getMinutes() < 10 ? '0' : '') + this.time.getMinutes();;
      const t = thr + ':' + tmn
      for (const val of this.trigger.runTimes2) {
        if (val === t) {
          this.timeexisterror = true;
          crt = false;
        }
      }
    }

    if (crt === true) {
      let go = true;
      const thr = (this.time.getUTCHours() < 10 ? '0' : '') + this.time.getUTCHours();
      const tmn = (this.time.getMinutes() < 10 ? '0' : '') + this.time.getMinutes();;
      const t = thr + ':' + tmn

      const tm = to12Hours(t);
      if (tm === undefined || tm === null) {
        // invalid time
        go = false;
        const title = await this.translate.get('GENERAL.ERROR').toPromise();
        const msg = await this.translate.get('GENERAL.INVALIDTIME').toPromise();
        this.showError(title, msg);
        return;
      }

      if (go === true) {
        this.showLoad();

        const time = new TriggerTime();
        time.Time = t;
        const res = await this.runtimeService.addRunTime(this.trigger.triggerId, time).toPromise()
        .catch(err => this.handleError(err));
        if (res !== undefined && res !== null && res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
          const msg = await this.translate.get('GENERAL.TIMEADDED').toPromise();
          this.showSuccess(title, msg);

          this.temptrigger = null;
          this.size = this.tpage * this.size;
          this.tpage = 1;
          await this.getTriggers();

          if (this.triggers != null && this.triggers.length > 0) {
            for (const val of this.triggers) {
              if (val.triggerId === this.trigger.triggerId) {
                await this.triggerClicked(val);
              }
            }
          }
          this.cancelTime();
        }

        this.hideLoad();
      }
    }
  }

  async addTime2() {
    let crt = true;
    this.timeerror = false;
    if (this.time === undefined || this.time === null) {
      crt = false;
      this.timeerror = true;
    }

    if (crt === true) {
      let go = true;
      const thr = (this.time.getHours() < 10 ? '0' : '') + this.time.getHours();
      const tmn = (this.time.getMinutes() < 10 ? '0' : '') + this.time.getMinutes();;
      const t = thr + ':' + tmn

      const tm = to12Hours(t);
      if (tm === undefined || tm === null) {
        // invalid time
        go = false;
        const title = await this.translate.get('GENERAL.ERROR').toPromise();
        const msg = await this.translate.get('GENERAL.INVALIDTIME').toPromise();
        this.showError(title, msg);
        return;
      }

      const array = [];
      const date = new Date();
      array.push(new Date(date.getFullYear(), date.getMonth(), date.getDate(), +thr, +tmn, 0))
      if (this.runtimes !== null && this.runtimes.length > 0) {
        for (const val of this.runtimes) {
          const splt = val.time.split(':');
          const hr = (splt[0].length < 2 ? '0' : '') + splt[0];
          const mn = (splt[1].length < 2 ? '0' : '') + splt[1];
          array.push(new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hr, +mn, 0))

          if (t === hr + ':' + mn) {
            go = false;
            const title = await this.translate.get('GENERAL.INFO').toPromise();
            const msg = await this.translate.get('GENERAL.TIMEEXISTS').toPromise();
            this.showWarn(title, msg);
            return;
          }
        }
      }

      if (go === true) {
        const vals = new Int64Updater();
        vals.Id = this.trigger.triggerId;
        vals.Values = [];

        const trigtimes = new KeyValue();
        trigtimes.Key = 'run_times';

        let durs = '';
        // sort
        array.sort((x, y) => +new Date(x) - +new Date(y));

        for (const val of array) {
          const tme = (val.getHours() < 10 ? '0' : '') + val.getHours()
                    + ':' +
                    (val.getMinutes() < 10 ? '0' : '') + val.getMinutes();
          durs += tme + ';';
        }

        trigtimes.Value = durs;

        vals.Values.push(trigtimes);

        this.showLoad();

      const res = await this.triggerService.putTriggers(vals).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
        const msg = await this.translate.get('GENERAL.TIMEADDED').toPromise();
        this.showSuccess(title, msg);

        this.temptrigger = null;
        this.size = this.tpage * this.size;
        this.tpage = 1;
        await this.getTriggers();

        if (this.triggers != null && this.triggers.length > 0) {
          for (const val of this.triggers) {
            if (val.triggerId === this.trigger.triggerId) {
              await this.triggerClicked(val);
            }
          }
        }
        this.cancelTime();
      }

      this.hideLoad();
      }
    }
  }

  async showConfirmDeleteTime(time) {
    this.temptime = time;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + time + '?',
    '', 'deletetriggertime');
  }

  onRejectDeleteTriggerTime() {
    this.messageService.clear('deletetriggertime');
  }

  async deleteTriggerTime() {
    const crt = true;

    const split = this.temptime.split(':');
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), split[0], split[1], 0);
    const newdate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                             date.getUTCHours(), date.getMinutes(), date.getSeconds());

    // const t = newdate.getHours() + ':' + newdate.getMinutes();
    const t = (newdate.getHours() < 10 ? '0' : '') + newdate.getHours() + ':'
              + (newdate.getMinutes() < 10 ? '0' : '') + newdate.getMinutes();
    if (crt === true) {
      const go = true;
      if (go === true) {
        this.showLoad();

        const time = new TriggerTime();
        time.Time = t;
        const res = await this.runtimeService.deleteRunTime(this.trigger.triggerId, time).toPromise()
        .catch(err => this.handleError(err));
        if (res !== undefined && res !== null && res === true) {
          // success
          const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
          const msg = await this.translate.get('GENERAL.TIMEDELETED').toPromise();
          this.showSuccess(title, msg);

          this.temptrigger = null;
          this.size = this.tpage * this.size;
          this.tpage = 1;
          await this.getTriggers();

          if (this.triggers != null && this.triggers.length > 0) {
            for (const val of this.triggers) {
              if (val.triggerId === this.trigger.triggerId) {
                await this.triggerClicked(val);
              }
            }
          }
          this.cancelTime();
          this.onRejectDeleteTriggerTime();
        }

        this.hideLoad();
      }
    }
  }

  cancelTime() {
    this.timeerror = false;
    this.timeexisterror = false;
    this.showaddtime = false;
    this.time = null;
  }

  cancelInterval() {
    this.iseditinterval = false;
    this.int = null;
    this.showeditinterval = true;
  }

  cancel() {
    this.iscreate = false;
    this.isedit = false;

    this.ctriggertype = null;
    this.triggername = null;
    this.triggerdesc = null;
    this.runtimemode = 'Interval';
    this.disableDurations = true;
    this.disableInterval = false;
    this.durations = null;
    this.interval = null;
    this.dow = 0;

    this.ctriggertypeerror = false;
    this.triggernameerror = false;
    this.triggerdescerror = false;
    this.intervalerror = false;
    this.durationserror = false;
    this.invaliddurations = false;

    this.temptrigger = this.trigger;

    this.master = 'x';
    this.detail = 'y';

    setTimeout(() => {
      this.onResize(null);
    }, (100));
  }

  cancel2() {
    this.iscreate = false;
    this.isedit = false;

    this.ctriggertype = null;
    this.triggername = null;
    this.triggerdesc = null;
    this.runtimemode = 'Interval';
    this.disableDurations = true;
    this.disableInterval = false;
    this.durations = null;
    this.interval = null;
    this.dow = 0;

    this.ctriggertypeerror = false;
    this.triggernameerror = false;
    this.triggerdescerror = false;
    this.intervalerror = false;
    this.durationserror = false;
    this.invaliddurations = false;

    this.temptrigger = this.trigger;

    this.master = 'w';
    this.detail = 'z';

    setTimeout(() => {
      this.onResize(null);
    }, (100));
  }

  async create() {
    let crt = true;

    this.ctriggertypeerror = false;
    this.triggernameerror = false;
    this.triggerdescerror = false;
    this.intervalerror = false;
    this.durationserror = false;
    this.invaliddurations = false;
    this.dowerror = false;

    if (this.ctriggertype === undefined || this.ctriggertype === null) {
      this.ctriggertypeerror = true;
      crt = false;
    }
    if (this.triggername === undefined || this.triggername === null || this.triggername.trim() === '') {
      this.triggernameerror = true;
      crt = false;
    }
    if (this.triggerdesc === undefined || this.triggerdesc === null || this.triggerdesc.trim() === '') {
      this.triggerdescerror = true;
      crt = false;
    }
    if (this.ctriggertype && this.ctriggertype === 'Scheduled trigger') {
      if (this.dow === undefined || this.dow === null || this.dow === 0 || this.dow.length === 0) {
        this.dowerror = true;
        crt = false;
      }
      if (this.runtimemode === 'Interval') {
        if (this.interval === undefined || this.interval === null) {
          this.intervalerror = true;
          crt = false;
        }
      } else {
        if (this.durations === undefined || this.durations === null || this.durations.length === 0) {
          this.durationserror = true;
          crt = false;
        } /*else if (!this.durations.includes(':')) {
          this.invaliddurations = true;
          crt = false;
        }*/ else {
          // check format of durations
          // const mydurations = this.durations.trim(';');
          // const split = mydurations.split(';');
          const split = this.durations;
          for (const val of split) {
            if (!val.includes(':')) {
              this.invaliddurations = true;
              crt = false;
              return;
            }
            if (val.trim() !== '') {
              const splt = val.split(':');
              const hr = (splt[0].length < 2 ? '0' : '') + splt[0];
              const mn = (splt[1].length < 2 ? '0' : '') + splt[1];
              const time = to12Hours(hr + ':' + mn); // 11:13 PM
              if (time === undefined || time === null) {
                this.invaliddurations = true;
                crt = false;
              }
            }
          }
        }
      }
    }

    if (crt === true) {
      const rgroups = [];
      if (this.inigroups2 !== null && this.inigroups2.length > 0) {
        for (const val of this.inigroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Initiator';
          rgroup.RoleParentType = 'trigger_definition';

          rgroups.push(rgroup);
        }
      }

      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'trigger_definition';

          rgroups.push(rgroup);
        }
      }

      const tparams = [];
      if (this.temptriggerparams !== undefined && this.temptriggerparams !== null && this.temptriggerparams.length > 0) {
        for (const val of this.temptriggerparams) {
          const tparam = new TriggerDefParam();
          tparam.TriggerDefParamName = val.TriggerDefParamName;
          tparam.TriggerDefParamDesc = val.TriggerDefParamDesc;

          tparams.push(tparam);
        }
      }

      const trig = new TriggerDefinition();
      trig.TriggerDesc = this.triggerdesc;
      trig.TriggerName = this.triggername;
      trig.TriggerType = this.ctriggertype;

      trig.RoleGroups = rgroups;
      trig.TriggerParams = tparams;

      if (this.ctriggertype === 'Scheduled trigger') {
        if (this.dow !== undefined && this.dow !== null && this.dow.length > 0) {
          trig.DayOfWeek2 = this.dow;
        }

        if (this.runtimemode === 'Interval') {
          trig.RunTimes = this.interval;
        } else {
          // const mydurations = this.durations.trim(';');
          // const split = mydurations.split(';');
          const split = this.durations;

          let durs = '';
          const array = [];
          const date = new Date();
          for (const val of split) {
            if (val.trim() !== '') {
              const splt = val.split(':');
              const hr = (splt[0].length < 2 ? '0' : '') + splt[0];
              const mn = (splt[1].length < 2 ? '0' : '') + splt[1];
              const tm = new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hr, +mn, 0);
              tm.setHours(tm.getUTCHours());
              array.push(tm);
            }
          }

          // sort
          array.sort((x, y) => +new Date(x) - +new Date(y));

          const times = [];
          for (const val of array) {
            const t = (val.getHours() < 10 ? '0' : '') + val.getHours()
                      + ':' +
                      (val.getMinutes() < 10 ? '0' : '') + val.getMinutes();
            durs += t + ';';
            times.push(t);
          }

          // trig.RunTimes = durs;
          trig.RunTimes2 = times;
        }
      }

      this.showLoad();

      const res = await this.triggerService.postTriggers(trig).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        this.temptrigger = null;
        this.size = this.tpage * this.size;
        this.tpage = 1;
        await this.getTriggers();

        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
        const msg = await this.translate.get('GENERAL.TRIGGERCREATED').toPromise();
        this.showSuccess(title, msg);

        this.cancel();

        if (this.triggers != null && this.triggers.length > 0) {
          for (const t of this.triggers) {
            if (t.triggerId === res) {
              await this.triggerClicked(t);
            }
          }
        }

        DataCache.loadData(this.http);
      }

      this.hideLoad();
    }
  }

  async save() {
    let crt = true;

    this.ctriggertypeerror = false;
    this.triggernameerror = false;
    this.triggerdescerror = false;
    this.intervalerror = false;
    this.durationserror = false;
    this.invaliddurations = false;
    this.dowerror = false;

    if (this.ctriggertype === undefined || this.ctriggertype === null) {
      this.ctriggertypeerror = true;
      crt = false;
    }
    if (this.triggername === undefined || this.triggername === null || this.triggername.trim() === '') {
      this.triggernameerror = true;
      crt = false;
    }
    if (this.triggerdesc === undefined || this.triggerdesc === null || this.triggerdesc.trim() === '') {
      this.triggerdescerror = true;
      crt = false;
    }
    if (this.ctriggertype && this.ctriggertype === 'Scheduled trigger') {
      if (this.dow === undefined || this.dow === null || this.dow === 0 || this.dow.length === 0) {
        this.dowerror = true;
        crt = false;
      }
      if (this.runtimemode === 'Interval') {
        if (this.interval === undefined || this.interval === null) {
          this.intervalerror = true;
          crt = false;
        }
      } else {
        if (this.durations === undefined || this.durations === null || this.durations.length === 0) {
          this.durationserror = true;
          crt = false;
        } /*else if (!this.durations.includes(':')) {
          this.invaliddurations = true;
          crt = false;
        }*/ else {
          // check format of durations
          // const mydurations = this.durations.trim(';');
          // const split = mydurations.split(';');
          const split = this.durations;
          for (const val of split) {
            if (!val.includes(':')) {
              this.invaliddurations = true;
              crt = false;
              return;
            }
            if (val.trim() !== '') {
              const splt = val.split(':');
              const hr = (splt[0].length < 2 ? '0' : '') + splt[0];
              const mn = (splt[1].length < 2 ? '0' : '') + splt[1];
              const time = to12Hours(hr + ':' + mn); // 11:13 PM
              if (time === undefined || time === null) {
                this.invaliddurations = true;
                crt = false;
              }
            }
          }
        }
      }
    }

    if (crt === true) {
      const vals = new Int64Updater();
      vals.Id = this.trigger.triggerId;
      vals.Values = [];

      const trigname = new KeyValue();
      trigname.Key = 'trig_name';
      trigname.Value = this.triggername;
      vals.Values.push(trigname);

      const trigdesc = new KeyValue();
      trigdesc.Key = 'trig_desc';
      trigdesc.Value = this.triggerdesc;
      vals.Values.push(trigdesc);

      if (this.ctriggertype === 'Scheduled trigger') {
        const dow = new KeyValue();
        dow.Key = 'dow';
        dow.Value = JSON.stringify(this.dow);
        // if (this.dow !== undefined && this.dow !== null && this.dow.length === 0) {
        //   dow.Value = this.dow.toString();
        // } else {
        //   dow.Value = '0';
        // }
        vals.Values.push(dow);

        const trigtimes = new KeyValue();
        trigtimes.Key = 'run_times';
        if (this.runtimemode === 'Interval') {
          trigtimes.Value = this.interval;
        } else {
          // const mydurations = this.durations.trim(';');
          // const split = mydurations.split(';');
          const split = this.durations;

          let durs = '';
          const array = [];
          const date = new Date();
          for (const val of split) {
            if (val.trim() !== '') {
              const splt = val.split(':');
              const hr = (splt[0].length < 2 ? '0' : '') + splt[0];
              const mn = (splt[1].length < 2 ? '0' : '') + splt[1];
              // array.push(new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hr, +mn, 0))

              const tm = new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hr, +mn, 0);
              tm.setHours(tm.getUTCHours());
              array.push(tm);
            }
          }

          // sort
          array.sort((x, y) => +new Date(x) - +new Date(y));

          const times = [];
          for (const val of array) {
            const t = (val.getHours() < 10 ? '0' : '') + val.getHours()
                      + ':' +
                      (val.getMinutes() < 10 ? '0' : '') + val.getMinutes();
            durs += t + ';';
            times.push(t);
          }

          // trigtimes.Value = durs;
          trigtimes.Value = JSON.stringify(times);
        }

        vals.Values.push(trigtimes);
      }

      // roles
      const rgroups = [];
      if (this.inigroups2 !== null && this.inigroups2.length > 0) {
        for (const val of this.inigroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Initiator';
          rgroup.RoleParentType = 'trigger_definition';
          rgroup.ParentId = this.trigger.triggerId;

          rgroups.push(rgroup);
        }
      }

      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'trigger_definition';
          rgroup.ParentId = this.trigger.triggerId;

          rgroups.push(rgroup);
        }
      }

      // trigger params
      const tparams = [];
      if (this.temptriggerparams !== undefined && this.temptriggerparams !== null && this.temptriggerparams.length > 0) {
        for (const val of this.temptriggerparams) {
          const tparam = new TriggerDefParam();
          tparam.TriggerDefParamName = val.TriggerDefParamName;
          tparam.TriggerDefParamDesc = val.TriggerDefParamDesc;
          tparam.TriggerDefinitionId = this.trigger.triggerId;

          tparams.push(tparam);
        }
      }

      const trigparam = new KeyValue();
      trigparam.Key = 'trig_params';
      trigparam.Value = JSON.stringify(tparams);
      vals.Values.push(trigparam);

      const data = new TypeRoleGroupData();
      data.ParentId = this.trigger.triggerId;
      data.RoleParentType = 'trigger_definition';
      data.TypeRoleGroup = rgroups;

      const rolegroups = new KeyValue();
      rolegroups.Key = 'role_groups';
      rolegroups.Value = JSON.stringify(data);
      vals.Values.push(rolegroups);

      this.showLoad();

      const res = await this.triggerService.putTriggers(vals).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        this.temptrigger = null;
        this.size = this.tpage * this.size;
        this.tpage = 1;
        await this.getTriggers();

        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
        const msg = await this.translate.get('GENERAL.TRIGGERUPDATED').toPromise();
        this.showSuccess(title, msg);

        if (this.triggers != null && this.triggers.length > 0) {
          for (const val of this.triggers) {
            if (val.triggerId === this.trigger.triggerId) {
              await this.triggerClicked(val);
            }
          }
        }

        DataCache.loadData(this.http);

        this.cancel();
      }

      this.hideLoad();
    }
  }

  async saveRoles() {
    this.loading = true;
    this.showLoad();

    // prepare role groups
    const rgroups = [];
    if (this.inigroups2 !== null && this.inigroups2.length > 0) {
      for (const vall of this.inigroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = vall.groupId;
        rgroup.RoleName = 'Initiator';
        rgroup.RoleParentType = 'trigger_definition';
        rgroup.ParentId = this.trigger.triggerId;

        rgroups.push(rgroup);
      }
    }

    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const vall of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = vall.groupId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'trigger_definition';
        rgroup.ParentId = this.trigger.triggerId;

        rgroups.push(rgroup);
      }
    }

    const rdata = new TypeRoleGroupData();
    rdata.ParentId = this.trigger.triggerId;
    rdata.RoleParentType = 'rule';
    rdata.TypeRoleGroup = rgroups;

    const res = await this.roleService.putRole(rdata).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      this.showSuccess(title, 'Rule role groups saved');

      // await this.closeEdit2();
    }

    this.hideLoad();
    this.loading = false;
  }

  closeEdit() {
    this.cancel();
  }

  // async closeEdit2() {
  //   this.loading = true;
  //   this.showLoad();

  //   this.size = this.size * this.tpage;
  //   this.tpage = 1;

  //   await this.getTriggers();

  //   if (this.trigger !== undefined && this.trigger !== null) {
  //     if (this.triggers !== null && this.triggers.length > 0) {
  //       for (const vl of this.triggers) {
  //         if (vl.triggerId === this.trigger.triggerId) {
  //           if (vl.enabled === 'Y') {
  //             this.rule.stat = 'Enabled';
  //             this.rule.statcolor = 'green';
  //           } else {
  //             this.rule.stat = 'Disabled';
  //             this.rule.statcolor = 'red';
  //           }

  //           if (new Date(vl.expiration).getFullYear() === 1) {
  //             this.translate.get('GENERAL.NEVER').subscribe((resp: string) => {
  //               this.rule.exp = resp;
  //             });
  //           } else {
  //             this.rule.exp = new Date(vl.expiration.toString().replace('T', ' ') + ' UTC').toLocaleString();
  //           }

  //           this.rule.enabled = vl.enabled;
  //           this.rule.expiration = vl.expiration;
  //           this.rule.lastRun = vl.lastRun;
  //           this.rule.ruleDesc = vl.ruleDesc;
  //           this.rule.ruleName = vl.ruleName;
  //           this.rule.ruleType = vl.ruleType;
  //           this.rule.stat = vl.stat;
  //           this.rule.statcolor = vl.statcolor;
  //           this.rule.timeinfo = vl.timeinfo;
  //           this.rule.trigger = vl.trigger;
  //           this.rule.triggerId = vl.triggerId;
  //           this.rule.ruleId = vl.ruleId;
  //           this.rule.typeRoleGroups = vl.typeRoleGroups;
  //           this.rule.roleGroups = vl.roleGroups;

  //           if (this.isadm === true) {
  //             this.iseditable = true;
  //           }

  //           this.ruleroles = [];

  //           const v = new Variables();
  //           const gis = localStorage.getItem('gi');
  //           const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
  //           const gids = JSON.parse(gs);

  //           for (const val of this.rule.typeRoleGroups) {
  //             if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
  //               this.iseditable = true;
  //             }

  //             val.typeRoleGroup.g = null;
  //             if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
  //               for (const g of this.gps) {
  //                 if (val.typeRoleGroup.groupId === g.groupId) {
  //                   val.typeRoleGroup.g = g.groupName;
  //                   val.typeRoleGroup.color = 'white';
  //                 }
  //               }
  //             }

  //             this.ruleroles.push(val.typeRoleGroup);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // this.cancel();

  //   this.hideLoad();
  //   this.loading = false;
  // }

  async showConfirmDeleteTrigger() {
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + this.trigger.triggerName + '?',
    '', 'deletetrigger');
  }

  onRejectDeleteTrigger() {
    this.messageService.clear('deletetrigger');
  }

  async deleteTrigger() {
    this.showLoad();
    const res = await this.triggerService.deleteTriggers(this.trigger.triggerId).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.TRIGGERDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteTrigger();

      await this.getTriggers();

      this.cancel();
      if (this.triggers != null && this.triggers.length > 0) {
        await this.triggerClicked(this.triggers[0]);
      }

      DataCache.loadData(this.http);
    }

    this.hideLoad();
  }

  async triggerClicked(trigger) {
    this.activeIndex = 0;
    this.activeIndex2 = 0;
    this.editmode = false;

    this.isshow = true;
    this.ishide = false;

    this.iseditinterval = false;
    this.showeditinterval = false;

    this.master = 'w';
    this.detail = 'z';
    this.isdetail = true;

    this.start = ' ';
    this.end = ' ';
    this.fromrange = null;
    this.tillrange = null;

    const vs = [];
    for (const val of this.triggers) {
      const v: any = [];
      v.lastRun = val.lastRun;
      v.next = val.next;
      v.nextRun = val.nextRun;
      v.runTimes = val.runTimes;
      v.triggerDesc = val.triggerDesc;
      v.triggerId = val.triggerId;
      v.triggerName = val.triggerName;
      v.triggerType = val.triggerType;
      v.dayOfWeek = val.dayOfWeek;
      v.runTimes2 = val.runTimes2;
      v.dayOfWeek2 = val.dayOfWeek2;
      v.typeRoleGroups = val.typeRoleGroups;
      v.roleGroups = val.roleGroups;
      if (val.triggerId === trigger.triggerId) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }

      vs.push(v);
    }

    this.triggers = vs;

    this.trigger = trigger;
    this.temptrigger = trigger;

    this.isaddtime = false;
    this.runtimes = [];
    if (trigger.triggerType != null && trigger.triggerType === 'Scheduled trigger') {
      if (trigger.runTimes == null) {
        if (trigger.runTimes2 !== undefined && trigger.runTimes2 !== null) {
          const split = trigger.runTimes2;
          // const split = trigger.runTimes.split(';');

          let ts = '';
          const times = [];
          let count = 1;
          for (const val2 of split) {
            if (val2.trim() !== '') {
              const date = new Date();
              const sp = val2.split(':');
              const tm = new Date(date.getFullYear(), date.getMonth(), date.getDate(), +sp[0], +sp[1], 0);
              const ntm = new Date(Date.UTC(tm.getFullYear(), tm.getMonth(), tm.getDate(),
              tm.getHours(), tm.getMinutes(), tm.getSeconds()));

              const time = new RunTime();
              time.time = val2;
              time.time = (ntm.getHours() < 10 ? '0' : '') + ntm.getHours()
               + ':' +
              (ntm.getMinutes() < 10 ? '0' : '') + ntm.getMinutes();
              time.color = 'white';
              this.runtimes.push(time);
              times.push(time.time);
              ts += time.time;
              if (count !== split.length) {
                ts += ';';
              }
              count += 1;
            }
            // this.trigger.runTimes = ts;
            this.trigger.runTimes2 = times;
          }

          this.isaddtime = true;
        }
      } else {
        const time = new RunTime();
        time.time = trigger.runTimes + ' min';
        time.color = 'white';
        this.runtimes.push(time);

        this.isaddtime = false;
        this.showeditinterval = true;
      }

      if (this.runtimes.length > 1) {
        this.isdeletetime = true;
      } else {
        this.isdeletetime = false;
      }
    }

    if (this.isadm === true) {
      this.iseditable = true;
    }

    // add roles
    this.ruleroles = [];
    if (trigger.typeRoleGroups !== null && trigger.typeRoleGroups.length > 0) {
      const v = new Variables();
      const gis = localStorage.getItem('gi');
      const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
      const gids = JSON.parse(gs);

      this.ruleroles = [];

      for (const val of trigger.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          this.iseditable = true;
        }

        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
              val.typeRoleGroup.color = 'white';
            }
          }
        }

        this.ruleroles.push(val.typeRoleGroup);
      }
    }

    this.triggerparams = [];

    await this.getTriggerParams();

    this.loading = false;
    this.onResize(null);
    this.cancel();
    this.master = 'w';
    this.detail = 'z';
  }

  async getTriggerParams() {
    this.loading = true;
    this.showLoad();

    const params = await this.trigParamService.getTriggerDefParams(this.trigger.triggerId).toPromise()
    .catch(err => this.handleError(err));

    this.triggerparams = [];

    if (params != null && params.length > 0) {
      for (const val of params) {
        val.color = 'white';
        this.triggerparams.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  triggerparamClicked(triggerparam) {
    const vs = [];

    for (const val of this.triggerparams) {
      const v: any = [];
      v.triggerDefParamDesc = val.triggerDefParamDesc;
      v.triggerDefParamName = val.triggerDefParamName;
      v.triggerDefinitionId = val.triggerDefinitionId;
      if (val.triggerDefParamDesc === triggerparam.triggerDefParamDesc && val.triggerDefParamName === triggerparam.triggerDefParamName) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }
      vs.push(v);
    }

    this.triggerparams = vs;
  }

  ruleRoleClicked(chanuser: any): any {
    const mcusers = [];
    for (const val of this.ruleroles) {
      const mcu: any = [];
      mcu.parentId = val.parentId;
      mcu.groupId = val.groupId;
      mcu.g = val.g;
      mcu.roleName = val.roleName;
      mcu.roleParentType = val.roleParentType;
      if (chanuser.groupId === val.groupId && chanuser.roleName === val.roleName) {
        mcu.color = 'lightblue';
      } else {
        mcu.color = 'white';
      }
      mcusers.push(mcu);
    }
    this.ruleroles = mcusers;
  }

  timeClicked(time) {
    const vs = [];

    if (this.iseditinterval === false) {
      for (const val of this.runtimes) {
        const v: any = [];
        v.time = val.time;
        if (val.time === time.time) {
          v.color = 'lightblue';
        } else {
          v.color = 'white';
        }
        vs.push(v);
      }

      this.runtimes = vs;
    }
  }

  showEditInterval() {
    for (const val of this.runtimes) {
      const split = val.time.split(' ');
      this.int = +split[0];
    }
    this.iseditinterval = true;
    this.showeditinterval = false;

    const vs = [];
    for (const val of this.runtimes) {
      const v: any = [];
      v.time = val.time;
      v.color = 'white';
      vs.push(v);
    }

    this.runtimes = vs;
  }

  async saveInterval() {
    const vals = new Int64Updater();
      vals.Id = this.trigger.triggerId;
      vals.Values = [];

      const trigtimes = new KeyValue();
      trigtimes.Key = 'run_times';
      trigtimes.Value = this.int.toString();
      vals.Values.push(trigtimes);

      this.showLoad();

    const res = await this.triggerService.putTriggers(vals).toPromise()
    .catch(err => this.handleError(err));
    if (res !== undefined && res !== null && res === true) {
      // success
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
      const msg = await this.translate.get('GENERAL.INTERVALUPDATED').toPromise();
      this.showSuccess(title, msg);

      this.temptrigger = null;
      this.size = this.tpage * this.size;
      this.tpage = 1;
      await this.getTriggers();

      if (this.triggers != null && this.triggers.length > 0) {
        for (const val of this.triggers) {
          if (val.triggerId === this.trigger.triggerId) {
            await this.triggerClicked(val);
          }
        }
      }
      this.cancelInterval();
    }

    this.hideLoad();
  }

  runtimeModeClicked() {
    if (this.runtimemode === 'Interval') {
      this.disableInterval = false;
      this.disableDurations = true;
    } else if (this.runtimemode === 'Durations') {
      this.disableInterval = true;
      this.disableDurations = false;
    }
  }

  async loadMoreT() {
    this.tpage += 1;
    await this.getTriggers();
  }

  async handleChange(e) {
    if (e.index === 1) {
      this.trpage = 1;
      this.triggerruns = [];
      await this.getTriggersRuns();
    }
  }

  async getTriggersRuns() {
    this.loading = true;
    this.showLoad();

    const rruns = await this.rulerunService
    .getRuleRuns(this.trpage, this.size, ' ', ' ', this.trigger.triggerId, this.start, this.end).toPromise()
    .catch(err => this.handleError(err));

    if (rruns != null && rruns.length > 0) {
      if (rruns.length >= this.size) {
        this.trloadmore = true;
      } else {
        this.trloadmore = false;
      }

      const doptions: any = { year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
      for (const val of rruns) {
        // val.strt = new Date(val.started.toString().replace('T', ' ') + ' UTC').toLocaleString();
        val.strt = new Date(val.started.toString().replace('T', ' ') + ' UTC')
          // .toLocaleString('fr-FR', doptions);
          .toLocaleString('en-US', doptions);
        if (new Date(val.finished).getFullYear() === 1) {
          val.fin = '-';
          val.runResult = '-';
          val.runComment = '-';
        } else {
          // val.fin = new Date(val.finished.toString().replace('T', ' ') + ' UTC').toLocaleString();
          val.fin = new Date(val.finished.toString().replace('T', ' ') + ' UTC')
            // .toLocaleString('fr-FR', doptions);
            .toLocaleString('en-US', doptions);
        }
        if (val.runResult === 'Passed') {
          val.color = 'green';
        } else if (val.runResult === 'Failed') {
          val.color = 'red';
        } else if (val.runResult === 'Skipped') {
          val.color = 'skyblue';
        }
        this.triggerruns.push(val);
      }
    } else {
      this.trloadmore = false;
      if (this.trpage === 1) {
        // no rule runs
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  showAddTriggerParam () {
    this.cancelTriggerParam();

    this.isaddtrigparam = true;
  }

  async addTriggerParam() {
    let crt = true;

    this.paramnameerror = false;
    this.paramdescerror = false;

    if (this.paramname === undefined || this.paramname === null || this.paramname.trim() === '') {
      crt = false;
      this.paramnameerror = true;
    }
    if (this.paramdesc === undefined || this.paramdesc === null || this.paramdesc.trim() === '') {
      crt = false;
      this.paramdescerror = true;
    }

    if (crt === true) {
      const tparam = new TriggerDefParam();
      tparam.TriggerDefParamDesc = this.paramdesc;
      tparam.TriggerDefParamName = this.paramname;
      if (this.trigger !== undefined && this.trigger !== null) {
        tparam.TriggerDefinitionId = this.trigger.triggerId;
      }

      if (this.temptriggerparams !== undefined && this.temptriggerparams !== null
        && this.temptriggerparams.length > 0) {
        let isadd = true;
        for (const tval of this.temptriggerparams) {
          if (tval.TriggerDefParamName === this.paramname) {
            // do not add
            isadd = false;
          }
        }

        if (isadd === true) {
          this.temptriggerparams.push(tparam);
        }
      } else {
        this.temptriggerparams.push(tparam);
      }

      this.paramname = null;
      this.paramdesc = null;
    }
  }

  async addTriggerParam2() {
    let crt = true;

    this.paramnameerror = false;
    this.paramdescerror = false;

    if (this.paramname === undefined || this.paramname === null || this.paramname.trim() === '') {
      crt = false;
      this.paramnameerror = true;
    }
    if (this.paramdesc === undefined || this.paramdesc === null || this.paramdesc.trim() === '') {
      crt = false;
      this.paramdescerror = true;
    }

    if (crt === true) {
      const tparam = new TriggerDefParam();
      tparam.TriggerDefParamDesc = this.paramdesc;
      tparam.TriggerDefParamName = this.paramname;
      tparam.TriggerDefinitionId = this.trigger.triggerId;

      this.showLoad();

      const res = await this.trigParamService.postTriggerDefParam(tparam).toPromise()
      .catch(err => this.handleError(err));

      if (res !== undefined && res !== null && res === true) {
        // success
        const title = await this.translate.get('GENERAL.SUCCESS').toPromise();
        const msg = await this.translate.get('GENERAL.TRIGGERPARAMETERADDED').toPromise();
        this.showSuccess(title, msg);

        await this.getTriggerParams();
        this.cancelTriggerParam();
      }

      this.hideLoad();
    }
  }

  async showConfirmDeleteTriggerParam(param) {
    this.trigparam = param;

    console.log(param, this.temptriggerparams)

    const tparams = [];
    for (const val of this.temptriggerparams) {
      if ((val.TriggerDefParamName === param.TriggerDefParamName && val.TriggerDefParamDesc === param.TriggerDefParamDesc)) {
        // do not add to list
      } else {
        tparams.push(val);
      }
    }

    this.temptriggerparams = tparams;
  }

  async showConfirmDeleteTriggerParam2(param) {
    this.trigparam = param;
    const title = await this.translate.get('GENERAL.DELETE').toPromise().catch(err => console.log(err));
    this.showConfirm(title + ' ' + param.triggerDefParamName + '?',
    '', 'deletetriggerparam');
  }

  onRejectDeleteTriggerParam() {
    this.messageService.clear('deletetriggerparam');
  }

  async deleteTriggerParam() {
    this.showLoad();
    const res = await this.trigParamService.deleteTriggerDefParam(this.trigger.triggerId,
       JSON.stringify(this.trigparam.triggerDefParamName)).toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      const title = await this.translate.get('GENERAL.SUCCESS').toPromise().catch();
      const msg = await this.translate.get('GENERAL.TRIGGERPARAMETERDELETED').toPromise().catch();
      this.showSuccess(title, msg);

      this.onRejectDeleteTriggerParam();

      await this.getTriggerParams();

      this.cancelTriggerParam();
    }

    this.hideLoad();
  }

  cancelTriggerParam() {
    this.paramname = null;
    this.paramdesc = null;

    this.paramnameerror = false;
    this.paramdescerror = false;

    this.isaddtrigparam = false;
  }

  async loadMoreTR() {
    this.trpage += 1;
    await this.getTriggersRuns();
  }

  async applyFilterRuns() {
    if (this.fromrange === undefined || this.fromrange === null || this.tillrange === undefined || this.tillrange === null) {
      this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
        this.confirm(res);
      });
      return;
    } else {
      if (this.fromrange > this.tillrange) {
        this.translate.get('GENERAL.FROMRANGECANNOTBEGREATERTHANTORANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.start = this.fromrange.toJSON().toString();
      this.end = this.tillrange.toJSON().toString();

      this.loading = true;

      // call function here
      this.trpage = 1;
      this.triggerruns = [];
      await this.getTriggersRuns();

      this.loading = false;
    }
  }

  toggleFilter(): any {
    this.isfilter = !this.isfilter;
  }

  async hideData() {
    this.ishide = true;
    this.isshow = false;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  showData() {
    this.ishide = false;
    this.isshow = true;
    setTimeout(() => {
      this.onResize(null);
    }, 100);
  }

  set() {
    this.fromrange = new Date(2020, 1, 9, 3, 3, 3, 3);
    this.tillrange = new Date();
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event !== undefined && event !== null) {
      const key = event.key;
      if (key !== undefined && key !== null && key === 'Enter' && this.isfilter === true) {
        this.apply();
      }
    }
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  onResize(event) {
    const menuheight = document.getElementById('menu')?.offsetHeight;
    const contentheight = window.innerHeight;

    const cwidth = document.getElementById('mycontentdiv')?.offsetWidth;
    let cheight = document.getElementById('maindata')?.offsetHeight;
    if (cheight === undefined || cheight === null) {
      cheight = 0;
    }

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (this.iscreate === true || this.isedit === true) {
      this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 25 - 30 + 228) + 'px';
      if (window.innerHeight > 700) {
        this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 25 - 30 + 0) + 'px';
      }
      const pdiv = (contentheight - menuheight - cheight - 10 - 200 + 15 - 40 - 235);
      const def = 150;
      if (pdiv < def) {
        this.pickerdiv = def + 'px';
        // this.pickerdiv = pdiv + 'px';
      } else {
        this.pickerdiv = pdiv + 'px';
      }
    } else {
      this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 0 - 5 + 15) + 'px';
    }
    // this.innerdiv = (contentheight - menuheight - cheight - 10 - 200 + 10) + 'px';
    this.runsdiv = (contentheight - menuheight - 150 + 15) + 'px';
    this.runsdiv2 = (contentheight - menuheight - 150 + 15 + 10) + 'px';
    this.innerinnerdiv = (contentheight - menuheight - cheight - 10 - 200 - 26 + 20) + 'px';
    this.innerinnerdivin = (contentheight - menuheight - cheight - 10 - 200 - 65 + 20) + 'px';
    this.creattriggerdiv = (contentheight - menuheight - 200 + 12) + 'px';
    // console.log(contentheight, cwidth, cheight, menuheight, this.contentdiv, this.innerdiv, this.runsdiv,
    //  this.innerinnerdivin, this.innerinnerdiv, this.creatrulediv)

    if (this.isedit === true && window.innerHeight < 700) {
      this.innerinnerdiv = '100%';
      this.innerinnerdivin = '350px';
    }
  }

  async handleError(err) {
    this.loading = false;
    this.hideLoad();
    if (err !== undefined && err !== null) {
      if (err.error !== undefined && err.error !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_rlm_trig_def_trig_def_name_key"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.TRIGGERALREADYEXISTS').toPromise();
          this.showWarn(title, msg, 10000);
        // } else if (err.error.MessageText === 'duplicate key value violates unique constraint "trig_def_param_unique"') {
        } else if (err.error.MessageText === 'duplicate key value violates unique constraint "t_rlm_trig_def_param_trig_def_id_trig_def_param_name_key"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.TRIGGERPARAMALREADYEXISTS').toPromise();
          this.showWarn(title, msg, 10000);
        } else if (err.error.MessageText === 'update or delete on table "t_rlm_trig_def" violates foreign key constraint "t_rlm_rule_trig_def_id_fkey" on table "t_rlm_rule"') {
          const title = await this.translate.get('GENERAL.INFO').toPromise();
          const msg = await this.translate.get('GENERAL.TRIGGERCANNOTBEDELETED').toPromise();
          this.showWarn(title, msg, 10000);
        } else {
          console.log(err);
        }
      } else {
        console.log(err);
      }
    }
  }

}

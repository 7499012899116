export class Role {
}

export class TypeRole {
    public RoleParentType: string;
    public RoleName: string;
    public RoleDescription: string;
}

export class TypeRoleGroup {
    public RoleParentType: string;
    public RoleName: string;
    public ParentId: number;
    public GroupId: number;
}

export class TypeRoleGroupData {
    public RoleParentType: string;
    public ParentId: number;
    public TypeRoleGroup: TypeRoleGroup[];
}

export class TypeRoleGroupInfo {
    public TypeRoleGroup: TypeRoleGroup[];
    public TypeRole: TypeRole[];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Urls } from 'src/app/helpers/urls';
import { Variables } from 'src/app/helpers/variables';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  url = new Urls();
  usmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = this.url.usmApiUrl;
  }

  getGroups(page, size) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + orgId + '/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroups(gid, uid) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/usergroups/Get_ByUserId/' + orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroupById(id: number) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + orgId + '/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // apiUrl: 'http://192.168.100.4:3071',

  // apiUrl: 'https://localhost:44363',
  // appUrl: 'http://localhost:4201',
  // usmApiUrl: 'http://192.168.100.4:3011',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // keycloakUrl: 'http://192.168.100.4:8088',
  // flmApiUrl: 'http://192.168.100.4:3081',
  // portalApiUrl: 'http://192.168.100.4:3001'

  // apiUrl: 'https://localhost:44363',
  // appUrl: 'http://localhost:4201',
  // usmApiUrl: 'http://10.8.0.156:3011',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // keycloakUrl: 'http://10.8.0.156:8088',
  // flmApiUrl: 'http://10.8.0.156:3081',
  // portalApiUrl: 'http://10.8.0.156:3001'

  // apiUrl: 'https://localhost:44363',
  // appUrl: 'http://localhost:4201',
  // usmApiUrl: 'http://localhost:3011',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // keycloakUrl: 'http://localhost:8088',
  // flmApiUrl: 'http://localhost:3081',
  // portalApiUrl: 'http://localhost:3001'


  // apiUrl: 'http://144.91.108.116:3071',
  // appUrl: 'http://144.91.108.116:4201',
  // usmApiUrl: 'http://144.91.108.116:3011',
  // realm: 'GoeplaRealm',
  // clientid: 'GoeplaClient',
  // keycloakUrl: 'https://144.91.108.116:8443',
  // flmApiUrl: 'http://144.91.108.116:3081',
  // portalApiUrl: 'http://144.91.108.116:3001'

  production: true,
  apiUrl: window["env"]["APIURL"] || "default",
  appUrl: window["env"]["APPURL"] || "default",
  usmApiUrl: window["env"]["USMAPIURL"] || "default",
  flmApiUrl: window["env"]["FLMAPIURL"] || "default",
  keycloakUrl: window["env"]["KEYCLOAKURL"] || "default",
  realm: window["env"]["REALM"] || "default",
  clientid: window["env"]["CLIENTID"] || "default",
  portalApiUrl: window["env"]["PORTALAPIURL"] || "default"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getActions(actionid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/actiondefinition/' + actionid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      // map(err => console.log(err))
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { Urls } from './urls';
import { Variables } from './variables';
import * as CryptoJS from 'crypto-js';

export class DataCache {
    static triggerdata$: Observable<any>;
    static conddefdata$: Observable<any>;
    static actiondefdata$: Observable<any>;
    static groupdata$: Observable<any>;

    static triggerData: any;
    static conddefData: any;
    static actiondefData: any;
    static groupData: any;

    static url = new Urls();
    static http: any;

    constructor(private ht: HttpClient) {
        DataCache.http = ht;
    }

    static loadData(ht: HttpClient) {
        DataCache.http = ht;
        const apiUrl = this.url.apiUrl;
        const usmApiUrl = this.url.usmApiUrl;

        const v = new Variables();
        const val = localStorage.getItem('oi');
        const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

        const orgId = +oi;

        const page = 0;
        const size = 0;
        const trigid = 0;
        const trigname = ' ';
        const trigtype = ' ';
        const condid = 0;
        const actionid = 0;

        // this.locdata$ = this.http.get(apiUrl + '/api/locations/' + page + '/' + size + '/' + orgId
        //  + '/' + loc + '/').pipe(
        //   tap(console.log),
        //   shareReplay(1),
        //   tap(() => console.log('after sharing'))
        // );

        // get trigger definitions
        this.triggerdata$ = this.http.get(apiUrl + '/api/triggerdefinition/' + trigid + '/' + trigname + '/' + trigtype
         + '/' + 0 + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get trigger definitions
        this.conddefdata$ = this.http.get(apiUrl + '/api/conditiondefinition/' + condid + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get trigger definitions
        this.actiondefdata$ = this.http.get(apiUrl + '/api/actiondefinition/' + actionid + '/').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get groups
        this.groupdata$ = this.http.get(usmApiUrl + '/api/groups/' + orgId).pipe(
          tap(),
          shareReplay(1),
          tap()
        );
    }

    getTriggerData() {
        DataCache.triggerdata$.subscribe(data => DataCache.triggerData = data);
    }

    getGetCondDefData() {
        DataCache.conddefdata$.subscribe(data => DataCache.conddefData = data);
    }

    getActionDefData() {
        DataCache.actiondefdata$.subscribe(data => DataCache.actiondefData = data);
    }

    getGroupData() {
      DataCache.groupdata$.subscribe(data => DataCache.groupData = data);
    }

    clearData() {
        DataCache.triggerData = null;
        DataCache.conddefData = null;
        DataCache.actiondefData = null;
        DataCache.groupData = null;
    }
}

import { TypeRoleGroupData } from './../../models/role';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/helpers/key-value';
import { Urls } from 'src/app/helpers/urls';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  putRole(val: TypeRoleGroupData) {
    return this.httpclient.put(this.apiUrl + '/api/typerolegroup/deletealladd/' + val.ParentId, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

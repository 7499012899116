import { ActionService } from './../../services/action/action.service';
import { ActionParamService } from './../../services/action-param/action-param.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RuleActionParamService } from 'src/app/services/rule-action-param/rule-action-param.service';
import { RuleActionService } from 'src/app/services/rule-action/rule-action.service';
import { RuleConditionService } from 'src/app/services/rule-condition/rule-condition.service';
import { RuleRunsService } from 'src/app/services/rule-runs/rule-runs.service';
import { RuleService } from 'src/app/services/rule/rule.service';
import { TriggerDefParamService } from 'src/app/services/trigger-def-param/trigger-def-param.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/helpers/variables';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  noText: string;

  actions = [];
  actionparams = [];
  action: any;

  loading = false;
  editmode = false;
  isdetail = false;

  isnoactions = false;

  divheight = '';
  divheight2 = '';
  innerdiv = '';
  runsdiv = '';
  creatrulediv = '';
  innerinnerdiv = '';
  innerinnerdivin = '';
  contentdiv = '';
  menuheight: any;
  master = 'x';
  detail = 'y';

  ismanage = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private actionservice: ActionService,
    private actParamService: ActionParamService
  ) {
    // this.translate.addLangs(['English', 'French']);
    // this.translate.setDefaultLang('English');

    // let browserLang = this.translate.getBrowserLang();

    // const pass = 'ranitessarldev2019';
    // const lang = cookieService.get('goepla-portal-language');
    // if (lang.trim() !== '') {
    //   const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
    //   const lg = declang;
    //   browserLang = lg;
    //   localStorage.setItem('language', lg);
    // }

    // if (localStorage.getItem('language') !== null) {
    //   browserLang = localStorage.getItem('language');
    // }

    // this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    translate.use('English');

    const v = new Variables();
    const mygroups = localStorage.getItem('mg');
    const gs = CryptoJS.AES.decrypt(mygroups, v.pass).toString(CryptoJS.enc.Utf8);
    if (gs.includes('Administrators-Full_Group')) {
      this.ismanage = true;
    }

    this.translate.get('GENERAL.NO').subscribe((resp: string) => {
      this.noText = resp;
    });
   }

  async ngOnInit() {
    await this.getActions();

    if (this.actions.length > 0 && window.innerWidth > 900) {
      await this.actionClicked(this.actions[0]);
    } else {
      this.onResize(null);
    }
  }

  showAddAction() {

  }

  async getActions() {
    this.loading = true;
    this.isnoactions = false;
    this.showLoad();

    const acts = await this.actionservice.getActions(0).toPromise()
    .catch(err => this.handleError(err));

    if (acts !== undefined && acts != null && acts.length > 0) {
      for (const val of acts) {
        val.color = 'white';
        this.actions.push(val);
      }
    } else {
      this.isnoactions = true;
    }

    this.hideLoad();
    this.loading = false;
  }

  async getActionParams() {
    this.loading = true;
    this.showLoad();

    const params = await this.actParamService.getActionParams(this.action.actionId, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (params !== undefined && params !== null && params.length > 0) {
      for (const val of params) {
        val.color = 'white';
        this.actionparams.push(val);
      }
    }

    this.hideLoad();
    this.loading = false;
  }

  async actionClicked(action) {
    this.editmode = false;
    this.showLoad();

    this.master = 'w';
    this.detail = 'z';

    const vs = [];
    for (const v of this.actions) {
      const val: any = [];
      val.actionDesc = v.actionDesc;
      val.actionId = v.actionId;
      val.actionName = v.actionName;
      if (v.actionId === action.actionId) {
        val.color = 'lightblue';
      } else {
        val.color = 'white';
      }
      vs.push(val);
    }

    this.actions = vs;

    this.action = action;

    this.actionparams = [];

    await this.getActionParams();

    this.isdetail = true;
    this.loading = false;

    this.onResize(null);
    this.hideLoad();
  }

  actionparamClicked(actionparam) {
    const vs = [];
    for (const val of this.actionparams) {
      const v: any = [];
      v.actionId = val.actionId;
      v.paramDesc = val.paramDesc;
      v.paramMandatory = val.paramMandatory;
      v.paramName = val.paramName;
      v.paramType = val.paramType;

      if (val.paramDesc === actionparam.paramDesc && val.paramName === actionparam.paramName && val.paramType === actionparam.paramType) {
        v.color = 'lightblue';
      } else {
        v.color = 'white';
      }
      vs.push(v);
    }

    this.actionparams = vs;
  }

  backClicked() {
    this.detail = 'y';
    this.master = 'x';
  }

  showLoad(): any {
    this.showConfirm('', '', 'loader', '');
  }

  hideLoad(): any {
    this.messageService.clear('loader');
  }

  showSuccess(title: string, details: string, duration: number = 3000): any {
    this.messageService.add({severity: 'success', summary: title, detail: details, key: 'tc', life: duration});
  }

  showInfo(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'info', summary: title, detail: details, key: 'tc', life: duration});
  }

  showWarn(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'warn', summary: title, detail: details, key: 'tc', life: duration});
  }

  showError(title: string, details: string, duration: number = 3000): any {
      this.messageService.add({severity: 'error', summary: title, detail: details, key: 'tc', life: duration});
  }

  showConfirm(title: string, details: string, keyvalue: string, severe: string = 'warn'): any {
    // this.messageService.clear();
    this.messageService.add({key: keyvalue, sticky: true, severity: severe, summary: title, detail: details});
  }

  onResize(event) {
    const menuheight = document.getElementById('menu')?.offsetHeight;
    const contentheight = window.innerHeight;

    const cwidth = document.getElementById('mycontentdiv')?.offsetWidth;
    const cheight = document.getElementById('maindata')?.offsetHeight;

    this.menuheight = menuheight;
    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 20) + 'px';
    } else {
      this.contentdiv = '85vh';
    }

    if (cwidth < 600) {
      // contentheight = contentheight - 20;
      // contentheight = contentheight - 15;
    } else {
    }
    this.innerdiv = (contentheight - menuheight - cheight - 145) + 'px';
    this.runsdiv = (contentheight - menuheight - 150 + 20) + 'px';
    this.innerinnerdiv = (contentheight - menuheight - cheight - 10 - 200 - 26 + 20) + 'px';
    this.innerinnerdivin = (contentheight - menuheight - cheight - 10 - 200 - 65 + 20) + 'px';
    this.creatrulediv = (contentheight - menuheight - 200 + 20) + 'px';
    // console.log(contentheight, cwidth, cheight, menuheight, this.contentdiv, this.innerdiv, this.runsdiv,
    //  this.innerinnerdivin, this.innerinnerdiv, this.creatrulediv)
  }

  handleError(err) {
    this.loading = false;
    this.hideLoad();
    console.log(err);
  }

}

import { RuleCondition } from './../../models/rule-condition';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/helpers/key-value';
import { Urls } from 'src/app/helpers/urls';
import { Rule } from 'src/app/models/rule';

@Injectable({
  providedIn: 'root'
})
export class RuleConditionService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRuleConditions(ruleid, condid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/rulecondition/' + ruleid + '/' + condid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRuleCondition(cond: RuleCondition) {
    return this.httpclient.post(this.apiUrl + '/api/rulecondition', cond)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  updateRuleCondition(ruleid: number, cond: RuleCondition) {
    return this.httpclient.put(this.apiUrl + '/api/rulecondition/' + ruleid, cond)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRuleCondition(ruleid: number, cond: RuleCondition) {
    return this.httpclient.put(this.apiUrl + '/api/rulecondition/delete/' + ruleid, cond)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

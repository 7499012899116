import { RuleActionParam } from 'src/app/models/rule-action-param';
export class RuleAction {
    public RuleId: number;
    public ActionId: number;
    public Position: number;
}

export class RuleActionData {
    public RuleAction: RuleAction;
    public RuleActionParams: RuleActionParam[];
}

import { RuleActionParam } from 'src/app/models/rule-action-param';
import { RuleAction, RuleActionData } from './../../models/rule-action';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';
import { RuleCondition } from 'src/app/models/rule-condition';

@Injectable({
  providedIn: 'root'
})
export class RuleActionService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getRuleActions(ruleid, actid) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/ruleaction/' + ruleid + '/' + actid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postRuleAction(act: RuleActionData) {
    return this.httpclient.post(this.apiUrl + '/api/ruleaction', act)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  updateRuleAction(ruleid, val: RuleActionParam[]) {
    return this.httpclient.put(this.apiUrl + '/api/ruleaction/' + ruleid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRuleAction(ruleid, actid) {
    return this.httpclient.put(this.apiUrl + '/api/ruleaction/delete/' + ruleid, actid)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}

import { BrowserComponent } from './components/browser/browser.component';
import { ActionComponent } from './components/action/action.component';
import { TriggerComponent } from './components/trigger/trigger.component';
import { RuleComponent } from './components/rule/rule.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'rules', component: RuleComponent },
  { path: 'triggers', component: TriggerComponent },
  { path: 'actions', component: ActionComponent },
  { path: 'xxxxx', component: BrowserComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { TriggerTime } from './../../models/trigger-time';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Urls } from 'src/app/helpers/urls';

@Injectable({
  providedIn: 'root'
})
export class RunTimesService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  addRunTime(triggerId: number, time: TriggerTime) {
    return this.httpclient.put(this.apiUrl + '/api/triggerruntimes/' + triggerId, time)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteRunTime(triggerId: number, time: TriggerTime) {
    return this.httpclient.put(this.apiUrl + '/api/triggerruntimes/delete/' + triggerId, time)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
